.data-loading-animation {
	width: 100px;
	$spinner-color: #A8A8A8;

	.bubble {
		float: left;
		height: 16px;
		margin-left: 5px;
		width: 16px;
		-moz-animation-name: bubble_anim;
		-moz-animation-duration: 1.05s;
		-moz-animation-iteration-count: infinite;
		-moz-animation-direction: normal;
		-moz-border-radius: 16px;
		-webkit-animation-name: bubble_anim;
		-webkit-animation-duration: 1.05s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-direction: normal;
		-webkit-border-radius: 16px;
		-ms-animation-name: bubble_anim;
		-ms-animation-duration: 1.05s;
		-ms-animation-iteration-count: infinite;
		-ms-animation-direction: normal;
		-ms-border-radius: 16px;
		-o-animation-name: bubble_anim;
		-o-animation-duration: 1.05s;
		-o-animation-iteration-count: infinite;
		-o-animation-direction: normal;
		-o-border-radius: 16px;
		animation-name: bubble_anim;
		animation-duration: 1.05s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		border-radius: 16px;
	}

	.bubble_1 {
		-moz-animation-delay: 0.21s;
		-webkit-animation-delay: 0.21s;
		-ms-animation-delay: 0.21s;
		-o-animation-delay: 0.21s;
		animation-delay: 0.21s;
	}

	.bubble_2 {
		-moz-animation-delay: 0.49s;
		-webkit-animation-delay: 0.49s;
		-ms-animation-delay: 0.49s;
		-o-animation-delay: 0.49s;
		animation-delay: 0.49s;
	}

	.bubble_3 {
		-moz-animation-delay: 0.63s;
		-webkit-animation-delay: 0.63s;
		-ms-animation-delay: 0.63s;
		-o-animation-delay: 0.63s;
		animation-delay: 0.63s;
	}

	@-moz-keyframes bubble_anim {
		0% {
		}
		50% {
			background-color: $spinner-color
		}
		100% {
		}
	}

	@-webkit-keyframes bubble_anim {
		0% {
		}
		50% {
			background-color: $spinner-color
		}
		100% {
		}
	}

	@-ms-keyframes bubble_anim {
		0% {
		}
		50% {
			background-color: $spinner-color
		}
		100% {
		}
	}

	@-o-keyframes bubble_anim {
		0% {
		}
		50% {
			background-color: $spinner-color
		}
		100% {
		}
	}

	@keyframes bubble_anim {
		0% {
		}
		50% {
			background-color: $spinner-color
		}
		100% {
		}
	}
}
