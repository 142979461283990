@media screen and (max-width: 768px) {
  header {
    .navbar {
      display: flex;
      min-height: 36px;
      .btn__login {
        background-color: #05b6a5;
        color: white;
        line-height: 1;
        position: relative;
        right: 40px;
      }
    }
  }
}
