.verification__form {
  margin-top: 32px;

  @media screen and (min-width: 767px) {
    margin-top: 64px;
  }

  .form-control {
    border-radius: 30px;
    height: 54px;
  }
}

.verification__wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .form-group {
    margin-bottom: 0;
    flex: 1 1 auto;

    @media screen and (min-width: 767px) {
      margin-right: 32px;
    }
  }
}

.verify__controls {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  margin-top: 16px;

  @media screen and (min-width: 480px) {
    margin-top: 0;
    flex: 0 0 auto;
  }

  button {
    &:not(:last-child) {
      @media screen and (min-width: 480px) {
        margin-right: 16px;
      }
    }
  }
}

.verify__controls__btn {
  @extend .btn, .btn-green, .btn-small;

  border: 0;
  color: white;
  height: 54px;
}

.verify__controls__btn--grey {
  @extend .btn, .btn-transparent, .btn-small;

  color: white;
  height: 54px;
}

.verification__errors {
  margin-top: 32px;
  font-size: 16px;
  background-color: #ea3b3b;
  color: white;
  padding: 16px;
}

.verification__success {
  margin-top: 32px;
  font-size: 16px;
  background-color: $second-color;
  color: white;
  padding: 16px;
}
