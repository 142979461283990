@mixin searchPanel {
	.search-panel {
		padding-bottom: 24px;
		padding-right: 8px;
		padding-left: 8px;
		position: sticky;
		top: 0;
		z-index: 1;
		background-color: #F8F8F8;
		.search-field {
			overflow: hidden;
			padding: 6px;
			padding-left: 42px;
			position: relative;
			&::before {
				content: '\f002';
				font-family: 'FontAwesome';
				position: absolute;
				left: 16px;
				color: #95989a;
			}
		}
		input[type='text'] {
			border: 0;
			background-color: inherit;
			color: #555353;
			font-size: 16px;
			&:focus {
				border-color: #fff;
				outline-color: #fff;
				transition: none;
			}
		}
	}
}

@mixin recipientListPanel {
	.recipients-list {
		padding-right: 8px;
		padding-left: 8px;
		.message-card {
			cursor: pointer;
			display: block;
			margin-bottom: 16px;
			padding: 16px 24px;
			position: relative;

			.user-image {
				position: absolute;
				top: 20px;
				left: 24px;
				border-radius: 50%;
				width: 36px;
			}
			.message-info {
				padding-left: 48px;
			}
			.user-title {
				margin: 0;
				padding: 0 15px 0 0;
				font-weight: 600;
				position: relative;
				span {
					position: absolute;
					top: 4px;
					right: 0;
					i {
						margin-right: 5px;
						color: #05b6a5;
					}
				}
			}
			.excerpt {
				margin: 0;
				padding: 0;
				font-size: 14px;
				font-weight: 400;
				color: #707c97;
			}
			&:hover,
			&.active-connection {
				background-color: #ee7f49;
				color: #fff;
				transition: all 0.5s ease 0s;
				-webkit-transition: all 0.5s ease 0s;
				-moz-transition: all 0.5s ease 0s;
				-o-transition: all 0.5s ease 0s;
				.user-title {
					color: #fff;
					i {
						color: #fff;
					}
				}
				.excerpt {
					color: #fff;
				}
			}
		}
	}
}

@mixin userInfoPanel {
	.user-info {
		cursor: pointer;
		display: block;
		padding: 20px 16px 14px 0;
		position: relative;
		background-color: #fafbff;
		min-height: 88px;
		.back-to-list {
			i.fa {
				font-weight: bold;
				margin-right: 8px;
				display: none;
				@media (max-width: 786px) {
					display: inline-block;
				}
			}
		}
		.user-image {
			position: absolute;
			top: 22px;
			left: 24px;
			border-radius: 50%;
			width: 47px;
		}
		.message-info {
			padding-left: 88px;
		}
		.user-title {
			margin: 0;
			padding: 0;
			font-weight: 400;
			position: relative;
			display: block;
			font-size: 20px;
			.user-badges {
				position: absolute;
				right: 0;
				span {
					width: 20px;
				}
			}
		}
		.excerpt {
			margin: 0;
			padding: 0;
			font-size: 13px;
			font-weight: 500;
			color: #05b6a5;
			opacity: 0.7;
		}
	}
}

@mixin messageText {
	&.message-text {
		color: #fff;
		padding: 8px 24px;
		margin-bottom: 40px;
		border-radius: 20px;
		display: inline-block;
		min-width: 300px;
		position: relative;
		.message-time {
			position: absolute;
			color: rgba(112, 124, 151, 0.7);
			bottom: -22px;
			font-size: 13px;
		}
	}
}
@mixin userMessagesPanel {
	.user-messages {
		height: 50vh;
		overflow-y: auto;
		overflow-x: hidden;
		.message-box {
			padding: 0 24px 0 64px;
			@media (max-width:400px) {
				padding: 0 10px;
			}
			p {
				margin-bottom: 0;
			}
			&.loggedin-user-message {
				p {
					@include messageText();
					&.message-text {
						background-color: #ee7f49;
						border-top-right-radius: 0;
						float: right;
						.message-time {
							right: 13px;
						}
					}
				}
			}
			&.recipient-message {
				position: relative;
				.recipient-photo {
					position: absolute;
					width: 40px;
					border-radius: 50%;
					left: 17px;
					top: 0;
					@media (max-width: 786px) {
						display: none;
					}
				}
				p {
					@include messageText();
					&.message-text {
						background-color: rgba(83, 83, 83, 0.6);
						border-top-left-radius: 0;
						.message-time {
							left: 16px;
						}
					}
				}
			}
			&.user-group {
				.message-text {
					margin-bottom: 20px !important;
				}
			}
		}
	}
}
@mixin userInputPanel {
	.message-input {
		border-top: 1px solid rgba(112, 124, 151, 0.15);
		min-height: 100px;
		padding: 8px 24px;
		@media (max-width:400px) {
			padding: 8px 8px 0 24px;
		}
		position: relative;
		textarea {
			color: rgb(112, 124, 151);
			font-size: 16px;
			border-color: transparent;
			padding-right: 102px;
			width: 100%;
			outline: none;

			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;

			resize: none;

			&::placeholder {
				color: rgba(112, 124, 151, 0.45);
			}
			&:disabled {
				background-color: transparent;
			}
		}
		.send-btn {
			position: absolute;
			right: 36px;
			@media (max-width:400px) {
				right: 8px;
			}
		}
	}
}

@mixin userNotifiationsPanel {
	.user-notification {
		background-color: rgba(#4d9cd3, 0.15);
		padding: 8px 32px;
		position: relative;
		margin-bottom: 8px;
		box-shadow: 0 8px 8px -3px rgba(0, 0, 0, 0.15);
		.msg-note {
			font-size: 13px;
			line-height: 20px;
			font-style: italic;
			color: darken(#4d9cd3, 20%);
		}
		&::before {
			font-family: 'FontAwesome', monospace;
			font-weight: 400;
			content: '\f06a';
			margin-right: 0.4em;
			font-size: 16px;
			color: #4d9cd3;
			position: absolute;
			left: 10px;
			top: 6px;
		}
		.btn-panel {
			padding-top: 5px;
			text-align: center;
		}
	}
}

@mixin abuseNotifiationsPanel {
	.message-abuse-title {
		background-color: rgba(#4d9cd3, 0.15);
		padding: 8px 32px;
		position: relative;
		margin: 16px 0 8px 0;
		box-shadow: 0 8px 8px -3px rgba(0, 0, 0, 0.15);
		font-size: 13px;
		line-height: 20px;
		font-style: italic;
		color: darken(#4d9cd3, 20%);
		@media (max-width:768px) {
			margin: 16px 8px 8px 8px;
		}
	}
}

.messagesWrapper {
	width: 100%;
	color: #555353;
	margin-bottom: 36px;
	@media (max-width:768px) {
		padding: 0;
	}
	padding-top: 10px;

	.messages-list {
		float: left;
		width: 33%;
		max-height: calc(282px + 50vh);
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		@media (max-width:768px) {
			width: 100%;
		}
		@media (min-width:801px) {
			margin-right: 8px;
		}
		@include searchPanel();
		@include recipientListPanel();

		@media (max-width:768px) {
			display: none;
			&.show-connections {
				display: block;
			}
		}
	}

	.message-detail-panel {
		float: left;
		width: 66%;
		min-height: 50vh;
		overflow: hidden;
		@media (max-width:768px) {
			width: 100%;
		}
		@media (max-width:1024px) {
			margin-left: 0;
		}
		@include userInfoPanel();
		@include userMessagesPanel();
		@include userInputPanel();
		@include userNotifiationsPanel();
		@media (max-width:768px) {
			display: none;
			&.show-connection-Details {
				display: block;
			}

		}
		
	}
	@include abuseNotifiationsPanel();
}
