$tooltip-button-color: #20B2AA;

.tooltip-info-bubble {
	.fa {
		color: $tooltip-button-color;
		font-size: 15px;
		padding: 6px;
		margin: 0;
	}

	i {
		flex: 0 0 auto;

		&.fa-info-circle {
			color: $tooltip-button-color;
		}
	}
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
	border-top-color: gray !important;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: gray !important;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-bottom-color: gray !important;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
	border-bottom-color: gray !important;
}

.tooltip {
	margin-bottom: 10px;
	margin-top: 10px;
}

.tooltip .tooltip-inner {
	color: white;
	background-color: gray !important;
	font-size: 12px;
	min-width: 250px;
}
