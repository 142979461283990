@import 'FilterSelect/FilterSelect';

@mixin more-btn {
	border: 0 none;
    background-color: #ef7f49;
    border-radius: 1.5em;
    padding: 6px 12px;
    font-size: 13px;
    color: #FFF;
	cursor: pointer;
	font-weight: 600;
	line-height: 23px;
	min-height: 38px;
	i {
		margin-left: 5px;
		font-size: 13px;
		position: relative;
		top: 1px;
		color: #FFF;
	}
	&:focus{
		outline: none;
	}
}

@mixin tab-headings {
	background-color: rgba(229, 229, 229, 0.6);
	color: #888;
	border-radius: 17px 17px 0px 0px;
	height: 33px;
	margin: 0 4px;
	padding: 3px 20px;
	outline: none !important;
	border: 0;
	cursor: pointer;
	font-size: 13px;

	&.households {
		&.active, &.active:hover {
			background-color: rgba(77, 156, 211, 0.75);
			color: white;
			font-weight: bold;
		}
		&:hover {
			background-color: rgba(77, 156, 211, 0.75);
			color: white;
		}
	}

	&.housemates {
		&.active, &.active:hover {
			background-color: rgba(5, 182, 166, 0.75);
			color: white;
			font-weight: bold;
		}
		&:hover {
			background-color: rgba(5, 182, 166, 0.75);
			color: white;
		}
	}
	
}

.xchange-search-container {
	width: 100%;
	margin: 0 0 3em;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;

	.react-autosuggest__container {
		display: inline;

		input[type=text].location-autosuggest + .react-autosuggest__suggestions-container {
			margin: 16px 0 0 25px;
			width: 96%;
		}
	}

	.search-mode-button-group {
		position: relative;
		z-index: 1;
		padding-left: 28px;

		button {
			@include tab-headings;
		}
	}

	.fa-search {
		padding: 10px 10px;
		position: absolute;
		top: 4px;
		opacity: 0.5;
		z-index: 1;
	}

	input[type=text] {
		width: 100%;
		border: none;
		font-weight: normal;
		outline: none;
		padding: 0 103px 0 32px;
		&::placeholder {
			opacity: 0.5;
		}
		@media screen and (max-width: 600px) {
			width: 60%;
			padding-right: 0;
		}
	}

	button.search-btn {
		background-color: #ef7f49;
		border: none;
		border-radius: 20px;
		margin: 0px 7px;
		padding: 0px 20px;
		outline: none !important;
		float: right;
		cursor: pointer;
		font-weight: 600;
		position: absolute;
		top: 7px;
		right: 1px;
		min-height: 25px;

		&:disabled {
			background-color: #E5E5E5;
			color: #A2A2A2;
		}
	}

	.search-bar-wrapper {
		position: relative;
		z-index: 2;
		display: block;
		margin-bottom: 0;
		padding: 10px 6px;
		border-radius: 35px;
	}
	.filter-section{
		position: relative;
		min-height: 54px;
		button{
			&.more-btn{
				@include more-btn;
			}
		}
		.dropdown-lists {
			background-color: rgba(229, 229, 229, 0.4);
			padding: 10px;
			margin: 0 32px;
			border-radius: 0 0 0.7em 0.7em;
			display: block;
		}
	}
	.total-results{
		float: right;
		margin-right: 24px;
		color: #999;
	}
	
}
