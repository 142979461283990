$errorColor: #cf2c2c;

.font-weight-semi-bold
{
	font-weight: 500;
}
.error
{
	color: $errorColor;
	margin-bottom: 0;
}

.error-field
{
	border: 1px solid $errorColor !important;
	box-shadow: unset !important;
}
