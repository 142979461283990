/*FOOTER*/
footer {
  .fa {
    color: #fff;
  }
  .inner-footer {
    padding: 70px 0px 40px;
    margin-bottom: 20px;
    .col-12 {
      margin-bottom: 10px;
    }
    h3 {
      margin-bottom: 40px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      @media (max-width: 976px) {
        margin: 15px 0px;
      }
    }
    .ft-menu ul {
      margin-bottom: 55px;
    }
    ul {
      list-style: none;
      padding: 0px;
      li {
        display: block;
        font-size: 20px;
        margin-bottom: 6px;
      }
    }
    .btn {
      height: 50px !important;
      width: 140px !important;
      font-size: 12px !important;
      padding: 0 40px !important;
      line-height: 49px;
      width: auto !important;
      border: 0;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 1199px) {
        padding: 0 15px !important;
        font-size: 10px !important;
        line-height: 39px;
        height: 40px !important;
      }
      @media (max-width: 976px) {
        width: 110px !important;
        height: 30px !important;
        padding: 0 20px !important;
        margin-bottom: 0.5rem;
        line-height: 30px !important;
      }
    }
    .btn-org {
      margin-right: 10px;
      @media (max-width: 976px) {
        margin-left: 0;
      }
    }
    .btn-transparent {
      margin-right: 1em;
      margin-left: 20px !important;
      background-color: #555353;
      color: white;
    }
    span {
      color: $primary-color;
      font-size: 24px;
      a {
        color: $primary-color;
      }
    }
    .fa-telegram {
      font-size: 50px;
      color: #555353;
      position: absolute;
      right: 0;
      z-index: 100;
      @media (max-width: 1199px) {
        font-size: 40px;
      }
    }
    a.contact {
      line-height: 20px;
      display: inline-block;
      margin-bottom: 7px;
    }
    #instafeed {
      img {
        width: 22%;
        margin-right: 2%;
        margin-bottom: 4px;
      }
    }
    .form-inline {
      margin-bottom: 15px;
      .form-group {
        width: 100%;
        margin-bottom: 1rem !important;
        .input-group {
          width: 100%;
          > div {
            width: 100%;
          }
        }
      }
      .has-error {
        word-wrap: break-word;
      }
      .form-control {
        border-radius: 1.25rem;
        width: 100% !important;
        height: 50px;
        margin: 0px;
        padding: 10px;
        border-right-color: #ffffff;
        padding: 10px 35px 10px 10px;

        &:focus {
          border-color: #ced4da;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  /*COPYRIGHT*/
  .copyright {
    background: #ffffff;
    padding: 30px 0 40px 0;
    img {
      height: 40px;
      width: auto;
      @media (max-width: 976px) {
        height: auto;
      }
    }
    span {
      float: right;
      text-align: right;
      font-size: 14px;
      letter-spacing: 0px;
      float: right;
      a {
        color: #9a9696;
      }
    }
  }
}

.footer__with-cookie {
  margin-bottom: 48px;
}
