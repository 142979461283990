@mixin getColorConfig($color) {
	background-color: $color;
	&:hover {
		background-color: darken($color, 10%);
	}
	&:disabled{
		background-color: rgba($color, 0.65);
		color: #FFF;
		cursor: default;
	}
	&.disabled {
		background-color: rgba($color, 0.65);
		color: #FFF;
		cursor: default;
	}
}

div, button, span, a {
	&.button {
		min-width: 90px;
		font-size: 14px;
		font-family: Montserrat, sans-serif;
		font-weight: 500;
		padding: 5px 15px;
		border-radius: 30px;
		border: none;
		outline: none;
		color: #FFF !important;
		cursor: pointer;
        min-height: 42px;
        line-height: 32px;
        display: inline-block;
        text-align: center;

        & ~ .button{
            margin-left: 8px;
        }

		&.btn-orange {
			@include getColorConfig(#ee7f49);
		}
		&.btn-teal {
			@include getColorConfig(#05b6a5);
		}
		&.btn-grey {
			@include getColorConfig(#535353);
		}
		&.btn-blue {
			@include getColorConfig(#4d9cd3);
		}
		&.button-medium{
			min-width: 216px;
		}
		&.button-large{
			min-width: 140px;
			line-height: 50px;
		}
	}
}
