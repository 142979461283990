.user-self-bar-wrapper {
	display: flex;
	width: 100%;
	margin-bottom: 4.25em;

	.profile-image-wrapper {
		display: flex;
		flex-direction: column;
		margin-right: 1.5em;
		vertical-align: top;
	}

	.user-details {
		display: flex;
		flex-direction: column;
		flex: 1;

		.user-full-name {
			font-weight: 600;
			display: inline;
			font-size: 1.2em;
			margin-bottom: 0.7em;
		}

		.user-detail-wrapper {
			display: flex;

			.user-self-ready-to-xchange {
				flex-grow: 1;
				flex-basis: 0;

				.ready-header {
					display: block;
					font-weight: 600;
				}

				.ready-content {
					display: block;
				}
			}

			.user-self-location {
				flex-grow: 1;
				flex-basis: 0;

				.location-header {
					display: block;
					font-weight: 600;
				}

				.location-content {
					display: block;

					.location-edit-wrapper {
						display: flex;

						.react-autosuggest__container {
							display: flex;
							flex-grow: 1.5;
							flex-basis: 0;

							input[type=text] {
								border: 0 solid #666666;
								border-bottom-width: 1px;
								display: inline;
								width: 100%;
							}
						}

						.location-edit-controls {
							display: flex;
							flex-grow: 0.5;
							flex-basis: 0;
							vertical-align: baseline;
						}
					}
				}
			}
		}
	}

	.user-badges {
		border-left: 1px solid #CCC;
		padding-left: 1em;
		min-width: 150px;

		.badges-header {
			display: block;
			font-weight: 600;
			margin-bottom: 0.5em;
		}

		.badges-no-results {
			display: block;

			p {
				display: block;
				line-height: 1.1em;
				margin-bottom: 0.1em;
			}

			a {
				font-size: 1em;
				margin-left: 0;
			}
		}

		.user-verification-badges img {
			width: 30px;
		}

		.more-info-badges {
			display: block;
		}
	}
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
		flex: 100%;
		.profile-image-wrapper {
			flex: 20%;
			margin: 0;
		}
		.user-details {
			flex: 50%;
			a {
				display: block;
			}
		}
		.user-detail-wrapper {
			flex: 100%;
			> div {
				flex: 50%;
			}
			.location-edit-wrapper {
				flex-wrap: wrap;
				flex: 100%;
				div,
				span {
					flex-basis: unset !important;
				}
				.location-edit-controls {
					a {
						margin-right: 5px;
					}
				}
			}
		}
		.user-badges {
			flex: 100%;
			border: 0 none;
			border-top: 1px solid #CCC;
		}
	}
}
