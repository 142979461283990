@charset "utf-8";
/* CSS Document */


a,
ul,
ul li,
.btn,
.slick-dots li button:before,
input,
i,
.slick-slide,
img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

html,
body {
  /* font-size: 16px!important; */
  overflow-x: hidden;
}

p {
  font-size: 16px;
}
.nopadding {
  padding: 0;
}

#layout-content .main {
  background-position: center -10px;
  background-size: cover;
}

header .logo {
  padding: 0 !important;
}
.slick-prev:before,
.slick-next:before {
  color: #c1c1c1 !important;
}

.submain-text {
  font-size: 56px;
  width: 100%;
  display: block;
  font-family: 'Montserrat', sans-serif;
  line-height: 66px;
}

.video {
  padding: 0;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sub-trx-works span.rm-main {
  font-size: 19px !important;
  line-height: 30px !important;
}
.host-item {
  margin-bottom: 30px;
  display: table;
}
.host-item img {
  width: 100%;
  height: auto;
}

.host-image {
  padding-right: 0;
}
.subworks {
  margin-top: 0 !important;
}
.subtrx p {
  font-size: 16px;
  margin-top: 20px;
}
.subtrx .orange-button {
  margin-right: 5px;
}
.subtrx .grey-button {
  margin-left: 5px;
}
.host-wrapper {
  border: solid 1px #f1f1f1;
  overflow: hidden;
}

.host-item .host-image {
  float: left;
  width: 50%;
  display: table-cell;
}
.host-item .host-right {
  border-left: 0;
  float: left;
  width: 50%;
  min-height: 251px;
  position: relative;
}
.subtrx {
  padding: 70px 0 40px !important;
}
.host-item .host-content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 25px 25px 15px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.host-content p {
  margin-bottom: 10px;
  font-size: 16px;
}

.host-content p span {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #ef7f49;
  font-size: 20px;
}

.sub-trx-works {
  padding: 70px 0 30px !important;
}
.hosting-blocks {
  padding: 0 0 60px;
}

.could-host span.rm-main {
  font-size: 19px !important;
  line-height: 30px !important;
}
.heading-block {
  text-align: center;
  padding: 60px 0 35px;
}
.heading-block h3,
h3 {
  font-size: 24px;
  font-weight: normal;
  color: #555353;
  width: 100%;
  clear: both;
  display: block;
  margin-bottom: 15px;
  letter-spacing: inherit;
  font-family: 'Montserrat', sans-serif !important;
}
.heading-block p {
  width: 100%;
  font-size: 20px !important;
  color: #555353;
  line-height: 32px;
  font-family: 'Montserrat', sans-serif !important;
}

.page-block {
  padding: 60px 0;
  border-bottom: solid 1px #f7f7f7;
}
#accordion,
#accordion2 {
  margin-bottom: 20px;
}
.page-block img {
  width: 100%;
  height: auto;
}
.card-header h5 button {
  width: 100%;
  text-align: left;
}
.card-header h5 button:after {
  font-family: FontAwesome;
  content: '\f107';
  float: right;
}
.card-header h5 button.collapsed:after {
  content: '\f105';
}

.blog-category a,
.blog-category select {
  float: right;
  margin: 0;
}

.blog-category select {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.blog-item {
  padding: 0;
  width: 100%;
}
.blog-item > li {
  list-style: none;
}
.blog-item > li {
  float: left;
  margin-bottom: 30px;
}
.blog-item > li:nth-child(3n + 1) {
  clear: left;
}
.blog-titles {
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  display: inline-block !important;
  width: auto !important;
}

.blog-item h2 {
  font-size: 18px;
  margin-bottom: 10px;
  letter-spacing: 0;
}
.blog-item .read-more {
  font-size: 14px;
  margin: 0;
}
.blog-item p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.blog-titles.news {
  color: #ef7f49;
}
.blog-titles.sharing {
  color: #7bd5f0;
}
.blog-titles.safety {
  color: #6349ef;
}
.blog-titles.travel {
  color: #7bd5f0;
}
.back-to-blog {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  float: right;
}
.back-to-blog:before {
  font-family: FontAwesome;
  content: '\f104';
  margin-right: 8px;
}
.blog-detailed-banner {
  min-height: 60vh;
  background-size: cover;
  background-position: center center;
  background-color: #ccc;
  margin-top: 15px;
  margin-bottom: 25px;
}
.blog-detailed .blog-item p {
  font-size: 14px;
}
.blog-detailed .blog-titles {
  margin: 0 !important;
}

.blog-detailed .blog-item p strong {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold;
  color: #ef7f49;
}
.blog-detailed h1 {
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.blog-block {
  padding: 20px 0;
}
.blog-item li span {
  display: block;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}
.blog-detailed .blog-item li {
  margin: 10px 0;
}

.social-share {
  width: 100%;
  margin: 0;
  padding: 0;
}
.social-share li {
  clear: inherit !important;
}
.social-share li a {
  display: block;
  float: left;
  margin: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 0;
  line-height: 45px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  background-color: #ef7f49;
  margin-right: 5px;
}

.contact-form {
  width: 100%;
  display: block;
}

.contact-form label {
  width: 100%;
  display: block;
  font-size: 14px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  max-width: 500px;
  border: solid 1px #ccc;
}

.contact-form input[type='submit'] {
  color: #fff;
  background-color: #ef7f49;
  border: 0;
  max-width: 200px;
  font-size: 13px;
  cursor: pointer;
  display: block;
  border-radius: 40px;
}
.contact-form input[type='submit']:hover {
  opacity: 0.75;
}
.contact-right p strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #ef7f49;
}
.contact-right p {
  margin: 0;
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 20px;
}

.contact-right ul,
.contact-right ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact-right ul li {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ccc;
}
iframe {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}

.navbar {
  padding: 0;
}

.header-wrapper.loggedin-header{
  min-height: 145px;
}
@media (min-width: 769px) {
  .header-wrapper.loggedin-header{
    margin-bottom: 20px;
  }
}

.header-wrapper.loggedin-header header{
  padding-bottom: 0;
}

header {
  padding: 20px 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

header nav a {
  font-size: 14px;
}

/* .navbar-nav li.nav-item {
height: 50px;
line-height: 50px;
} */
header nav .fa {
  height: 50px;
  padding: 0;
  margin: 0 0 0 15px;
}

.navbar-nav li a.btn.btn-org {
  border: 0 !important;
  margin-right: 0 !important;
  background-color: #ef7f49 !important;
}

/* .navbar-nav li a.btn:hover,
.btn-transparent:hover {
opacity: 0.75;
border: 0!important;
} */

.main .inner-main .header-text {
  font-size: 62px;
}

.main-des .rm-main {
  font-size: 24px;
}

.main-des {
  margin: 40px 0;
}
.main .inner-main {
  line-height: inherit !important;
}

.main .inner-main > div {
  line-height: 5rem;
}

.main .inner-main div.find-more {
  line-height: inherit !important;
  margin-top: 20px !important;
}

.main {
  height: calc(100vh - 90px);
}
.submain {
  height: 70vh;
}
.find-more .btn-small {
  padding: 15px 80px;
  line-height: inherit;
  height: inherit;
  width: inherit;
}

.social-media ul li {
  line-height: 48px;
}

.main-text {
  font-size: 22px;
  line-height: 34px;
  text-align: left;
}

.block-meme {
  text-align: left;
}

.trx-img .img-block .block-des {
  position: relative;
}

.trx-img .img-block .block-des .main-text {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 75%;
  text-align: center;
}

.home-testimonial {
  background-color: #f1f1f1;
  padding: 80px 0;
}

.home-testimonial-list .slick-slide {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 6px -6px #ddd;
  -moz-box-shadow: 0 10px 6px -6px #ddd;
  box-shadow: 0 10px 6px -6px #ddd;
  overflow: hidden;
  height: 100%;
  margin-bottom: 10px;
}
.test-left {
  overflow: hidden;
  width: 50%;
  float: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  position: relative;
}

.test-left span {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  width: 20%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #ccc;
  display: block;
}
.slick-dotted.slick-slider {
  padding-bottom: 20px;
  margin-bottom: 0;
}
.slick-dots li button:before {
  opacity: 1 !important;
  font-size: 15px !important;
  color: #fff;
}
.slick-dots li:hover button:before {
  color: #c1c1c1 !important;
}
.slick-dots li.slick-active button:before {
  color: #c1c1c1 !important;
}
.home-testimonial-list .slick-slide.slick-center {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 6px -6px #ddd;
  -moz-box-shadow: 0 10px 6px -6px #ddd;
  box-shadow: 0 10px 6px -6px #ddd;
  border: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  margin: 0 0 10px 0;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}
.test-left img {
  width: 100%;
  height: 100%;
}
.slick-center .test-left img {
  width: 100%;
  height: 100%;
}
.main-des .social-media {
  margin-bottom: 50px;
  display: inline-block;
  width: auto;
  margin: 40px auto 0 auto;
}
.social-media ul li {
  line-height: 40px;
  background-color: transparent;
  display: inline-block;
  height: inherit;
  widows: inherit;
  border-radius: 0;
  margin: 0 2px;
}
.social-media ul li a {
  line-height: 48px;
  display: inline-block;
  background: #ef7f49;
  font-size: 13px;
  font-size: 24px;
  height: 48px;
  width: 48px;
  text-align: center;
  border-radius: 40px;
  margin-right: 5px;
}
.social-media ul li a:hover {
  opacity: 0.75 !important;
}
.social-media ul {
  display: inline-block;
  float: left;
  margin-bottom: 10px !important;
}
.social-media ul.social {
  margin-left: 20px;
}
.social-media ul li.social-host {
  width: inherit;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  height: inherit;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
.social-media ul li.social-host a {
  width: 100%;
  display: block;
  padding: 5px 40px;
  background-color: #ef7f49;
  border-radius: 40px;
  line-height: 40px;
  font-size: 13px;
}
.social-media ul li.social-guest {
  width: inherit;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  height: inherit;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
.social-media ul li.social-guest a {
  width: 100%;
  line-height: 40px;
  display: block;
  padding: 5px 40px;
  background-color: #555353;
  border-radius: 40px;
  font-size: 13px;
}

.test-image {
  width: 100%;
  border-radius: 50%;
  height: auto;
  border: solid 4px #ffbc6b;
}

.test-right {
  padding: 30px 0 0;
  width: 50%;
  float: left;
}

.test-right div {
  padding-left: 25px;
}

.test-right span {
  width: 100%;
  clear: both;
  float: left;
  line-height: 15px;
  display: block;
}
.test-name {
  margin-top: 15px;
}

.test-position {
  color: #ef7f49;
  font-size: 11px;
  margin-top: 5px;
}

.test-date {
  font-size: 11px;
}

.test-right-image {
  float: left;
  width: 27%;
}

.test-right-details {
  float: left;
  width: 73%;
}

.test-right-content {
  width: calc(100% - 25px);
  float: left;
  margin-top: 20px;
}
.test-right-content p {
  font-size: 17px;
}
.trx-works {
  padding: 70px;
}

.trx-works span.trx-works-header {
  font-size: 30px;
  font-weight: normal;
  color: #555353;
  width: 100%;
  clear: both;
  display: block;
  margin-bottom: 15px;
  letter-spacing: inherit;
  font-family: 'Montserrat', sans-serif !important;
}

.trx-works span.rm-main {
  width: 100%;
  clear: both;
  display: block;
  font-size: 24px;
  font-weight: normal;
  color: #555353;
  line-height: 2.2rem;
  letter-spacing: inherit;
  font-family: 'Montserrat', sans-serif !important;
}

.trx-works-wrap .main-text {
  text-align: center;
  line-height: 30px;
  font-size: 22px;
}

.orange-button {
  padding: 10px 40px;
  border-radius: 40px;
  background-color: #ef7f49;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.orange-button:hover {
  color: #fff !important;
  opacity: 0.75;
}

.grey-button {
  padding: 10px 40px;
  border-radius: 40px;
  background-color: #555353;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.grey-button:hover {
  color: #fff !important;
  opacity: 0.75;
}

.howitworks {
  display: block;
  width: 100%;
  margin-top: 80px;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
}

.trx-works .trx-works-wrap span.num-label {
  font-size: 50px;
  width: 145px;
  height: 145px;
}
.safe {
  background-image: url(/images/duck.jpg);
  background-size: cover;
  background-position: center center;
  padding: 90px 0 120px;
  position: relative;
  z-index: 1;
}

.safe:after {
  content: '';
  width: 100%;
  height: auto;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.25;
}

.safe h2 {
  text-align: center;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  margin-bottom: 50px;
  z-index: 10;
  display: block;
}

.safe-box {
  z-index: 10;
  border: solid 2px #fff;
  padding: 70px 0 75px;
  text-align: center;
  color: #fff;
  display: block;
}

.safe-title {
  color: #ef7f49;
  width: 100%;
  display: block;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: inherit;
  margin-bottom: 10px;
}

.safe-text,
.safe-digital {
  width: 100%;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
}

.safe-digital {
  background-image: url(/images/digitalid.png);
  background-repeat: no-repeat;
  background-position: top left;
  width: auto;
  padding-left: 40px;
  display: inline-block;
}

.orange-bar {
  padding: 40px 0;
  background-color: #ef7f49;
  text-align: center;
  color: #fff;
  font-size: 36px;
  line-height: 40px;
  font-family: 'Montserrat', sans-serif;
}

.orange-bar span {
  display: inline-block;
  border: solid 2px #fff;
  border-radius: 50%;
  padding: 10px;
}

.featured {
  padding: 90px 0;
  text-align: center;
}
.featured h2 {
  text-align: center;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
  color: #555353;
  margin-bottom: 40px;
  z-index: 10;
  display: block;
}
.featured ul,
.featured ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.featured ul {
  display: inline-block;
  margin: 0 auto;
}

.featured ul li {
  display: inline-block;
  margin: 0 10px 10px;
}
.featured ul li img {
  width: 100%;
  height: auto;
  max-width: 100px;
}
.copyright {
  font-size: 13px !important;
}
.copyright a:hover {
  color: #ef7f49;
}

footer .inner-footer h3 {
  font-size: 15px;
  margin-bottom: 10px;
}
footer .inner-footer ul li,
.ft-cont .text {
  font-size: 15px;
}
.ft-cont .orange-button {
  margin-top: 0;
}
footer .inner-footer .ft-menu ul {
  margin-bottom: 0;
}
footer .inner-footer span {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.ft-cont .orange-button,
.ft-cont .grey-button {
  float: left;
  margin-right: 5px;
  padding: 10px 30px;
}

header nav .fa:hover {
  cursor: pointer;
}

.card-header {
  padding: 0;
  border: 0;
  background-color: transparent;
  border-bottom: solid 1px #ef7f49;
  font-size: 16px !important;
}
.card {
  border: 0;
}
.card-header h5,
.card-header button {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  white-space: normal;
  padding: 5px 0px;
  line-height: normal;
}
.card-body {
  padding: 10px 0;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif;
  line-height: 22px !important;
}
.btn-link {
  color: #ef7f49;
}
.btn-link.collapsed {
  color: #555353;
}
.btn-link:active,
.btn-link:focus {
  text-decoration: none !important;
}
.btn-link:hover {
  text-decoration: none !important;
  color: #ef7f49 !important;
}
.nl-field.nl-dd {
  margin-left: 10px;
}
@media screen and (max-width: 1500px) {
  .host-content p span {
    font-size: 18px;
  }
  .host-content p {
    font-size: 15px;
    line-height: 22px;
  }
  .host-item .host-right {
    min-height: 220px;
  }
}
@media screen and (max-width: 1024px) {
  .host-item .host-content {
    padding: 10px 25px 0;
  }
  .host-content p {
    font-size: 14px;
    line-height: 20px;
  }
  .test-right-content p {
    font-size: 15px;
    line-height: 22px;
  }
  .test-right {
    padding-top: 20px;
  }
  .test-right div {
    padding-left: 20px;
  }
  .test-right-content {
    width: calc(100% - 20px);
  }
}
@media screen and (max-width: 1300px) {
  .host-item .host-right {
    min-height: 200px;
  }
}
@media screen and (max-width: 1199px) {
  .blog-detailed-banner {
    min-height: 50vh;
  }
  .blog-detailed h1 {
    font-size: 22px;
  }
  .back-to-blog {
    font-size: 12px;
  }
  .blog-item li:nth-child(3n + 1) {
    clear: inherit;
  }

  .blog-item li:nth-child(odd) {
    clear: left;
  }

  .host-item .host-image {
    display: none;
  }

  .host-item .host-right {
    width: 100%;
  }
  .heading-block h3 {
    font-size: 26px;
  }
  .heading-block p {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .host-item .host-content {
    padding: 20px 15px 10px;
    position: inherit;
    top: inherit;
    left: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit;
  }
  .host-item .host-right {
    min-height: inherit;
    height: 100%;
  }
  .host-content p span {
    font-size: 17px;
  }
  .host-content p {
    font-size: 15px;
    line-height: 22px;
  }
  .main {
    height: calc(100vh - 80px);
  }
  header nav a,
  header nav .fa {
    font-size: 14px;
    margin-left: 0;
  }
  .navbar-nav li a.nav-link {
    padding-left: 0 !important;
  }
  /* .navbar-nav li.nav-item,
	.navbar-nav li:last-child,
	header nav .fa {
	height: 40px;
	line-height: 40px;
	} */
  .navbar-nav li a.btn {
    padding: 0 15px !important;
    font-size: 10px !important;
    line-height: 40px;
  }
  header nav .btn {
    height: 40px;
  }
  .navbar-nav li a.btn.btn-org {
    margin-left: 0 !important;
  }
  .navbar-nav li a.btn {
    margin-left: 15px !important;
  }
  .trx-img .img-block .block-des {
    padding: 25px 20px;
  }
  .main-text,
  .trx-img .img-block .block-des a {
    font-size: 18px;
    line-height: inherit;
  }
  .trx-works span.trx-works-header {
    font-size: 26px;
    line-height: 32px;
  }
  .trx-works span.rm-main {
    font-size: 20px;
    line-height: 28px;
  }
  .howitworks,
  .safe h2 {
    font-size: 30px;
  }
  .orange-bar {
    font-size: 30px;
    line-height: 33px;
  }
  .trx-works .trx-works-wrap span.num-label {
    font-size: 36px;
    width: 100px;
    height: 100px;
  }
  .trx-works {
    padding: 50px;
  }
  .safe {
    padding: 70px 0 100px;
  }
  .trx-works-wrap .main-text {
    font-size: 18px;
    line-height: 24px;
  }
  .safe-title {
    font-size: 22px;
  }
  .safe-text,
  .safe-digital {
    font-size: 16px;
    line-height: inherit;
  }
  .safe-digital {
    background-size: auto 100%;
    padding-left: 30px;
  }
  .safe-box {
    padding: 50px 0 55px;
  }
  .featured h2 {
    font-size: 34px;
  }
  .featured {
    padding: 50px 0;
  }
  .copyright {
    font-size: 11px !important;
  }

  .form-inline .form-group {
    display: inherit;
    width: 100% !important;
  }
  .form-inline .form-group div {
    width: inherit;
  }
  footer .inner-footer .form-inline .form-control {
    width: 275px;
    height: 40px;
    padding: 0 15px;
    font-size: 12px;
    max-width: 100%;
  }
  .form-inline .input-group {
    width: 100% !important;
  }
  footer .inner-footer .fa-telegram {
    position: absolute;
    top: 0;
    font-size: 40px;
    right: 0;
    z-index: 80 !important;
  }

  .social-media ul li a {
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    float: left;
  }
  footer .inner-footer .social-media ul li {
    float: left;
    width: inherit;
    margin-right: 5px;
  }
  footer .inner-footer h3,
  footer .inner-footer ul li,
  .ft-cont .text,
  footer .inner-footer span {
    font-size: 13px;
  }
  footer .inner-footer ul li {
    margin-bottom: 2px;
  }

  .ft-cont .orange-button,
  .ft-cont .grey-button {
    padding: 5px 20px;
    font-size: 10px;
  }

  .ft-cont p {
    margin-bottom: 10px;
  }
  footer .copyright {
    padding: 20px 0 30px 0;
  }
  footer .copyright img {
    height: 30px;
  }
  .main .inner-main {
    line-height: 4rem;
  }
  .main .inner-main .header-text {
    font-size: 52px;
  }
  .main {
    height: calc(100vh - 70px);
  }
}

@media screen and (max-width: 991px) {
  .video {
    padding: 50px 0;
  }
  .submain-text {
    font-size: 31px;
    line-height: 44px;
  }
  .social-media ul {
    float: none;
  }
  .main .inner-main > div {
    line-height: 4rem;
  }
  .main {
    height: 60vh;
  }
  .test-right-content p {
    font-size: 14px;
    line-height: 20px;
  }
  .safe-box {
    margin-bottom: 30px;
    padding: 25px 0;
  }
  .trx-works {
    padding: 50px 15px;
  }
  .ft-cont .orange-button,
  .ft-cont .grey-button {
    width: 100%;
    margin-bottom: 10px !important;
    text-align: center !important;
  }
  .desktop-main-nav {
    display: none !important;
  }
  .mobile-main-nav {
    display: block !important;
  }
  .navbar > a {
    display: block;
    height: 30px;
    width: 200px;
    background-image: url(/themes/theroomxchange/assets/images/trx-logo.png);
    background-size: cover;
    background-position: center center;
    float: left;
  }
  header {
    padding: 15px 0;
  }
  .navbar-toggler {
    position: absolute;
    right: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  header .navbar {
    /* padding: 0;
		margin: 0; */
    width: 100%;
  }

  .navbar > .header-btn {
    position: absolute;
    right: 45px;
    /* right:0px; */
    line-height: 30px;
    z-index: 1;
  }

  .navbar > div > a {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    float: left;
    padding: 0 20px !important;
    margin: 0;
    height: inherit;
    line-height: inherit;
    margin-left: 10px;
  }
  .navbar-collapse {
    left: 0;
    right: inherit;
    background-color: #ed7f4f;
    width: 100%;
    color: #fff;
    top: 45px !important;
    padding: 20px 30px;
  }
  .navbar-collapse a {
    color: #fff !important;
    font-size: 18px !important;
  }

  .main .inner-main {
    line-height: 3rem;
  }
  .main .inner-main .header-text {
    font-size: 32px;
  }
  .find-more .btn-small {
    padding: 7px 60px;
    font-size: 13px;
  }
  .main-des .rm-main {
    font-size: 20px;
  }
  .main-des {
    margin: 30px 0;
    text-align: center;
  }
  .main-text,
  .trx-img .img-block .block-des a {
    font-size: 14px;
  }
  .social-media ul li.social-host,
  .social-media ul li.social-guest {
    margin-left: 10px !important;
  }

  .social-media ul li.social-host a,
  .social-media ul li.social-guest a {
    padding: 0 25px !important;
  }
  .social-media ul li {
    float: left;
  }
  .social-media ul li {
    width: 45px;
  }
  .main-des .social-media {
    margin-top: 20px;
    text-align: center;
  }
  .home-testimonial {
    padding: 50px 0;
  }
  .test-right-details {
    padding-left: 15px !important;
  }
  .test-name {
    margin-top: 10px;
  }
  footer .inner-footer .social-media ul li {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .blog-category select {
    border: 1px solid #cecece;
  }
  .blog-detailed-banner {
    min-height: 40vh;
  }
  .blog-detailed h2 {
    font-size: 16px;
  }
  .blog-detailed .blog-titles {
    margin-bottom: 10px !important;
  }
  .back-to-blog {
    font-size: 11px;
  }
  .video .container,
  .home-testimonial .container,
  .heading-block .container {
    margin: 0;
    padding: 0;
  }
  .blog-item li {
    margin-bottom: 20px;
  }
  .blog-item p {
    margin-bottom: 5px;
  }
  .blog-item h2 {
    font-size: 16px;
  }
  .blog-titles {
    margin-bottom: 5px;
  }
  #accordion,
  #accordion2 {
    margin-bottom: 40px;
  }
  .page-block {
    padding: 30px 0 50px;
  }

  .submain-text {
    font-size: 26px;
    line-height: 32px;
  }
  .subtrx {
    padding: 40px 0 10px !important;
  }
  .heading-block h3 {
    font-size: 24px;
  }
  .heading-block p {
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .host-item .host-content {
    padding: 15px 15px 0;
  }
  .host-content p {
    font-size: 14px;
  }
  .home-testimonial .container {
    max-width: inherit;
  }
  .main {
    height: 60vh;
  }
  .safe {
    padding: 60px 0;
  }

  footer .inner-footer {
    padding: 40px 0;
  }
  .copyright .col-sm-12 {
    text-align: left !important;
  }
  footer .copyright {
    padding: 5px 0 25px;
  }
  footer .inner-footer .form-inline {
    margin-bottom: 0;
  }
  .orange-bar {
    font-size: 24px;
    line-height: 28px;
    padding: 20px 0;
  }
  .heading-block {
    padding: 40px 10px 10px;
  }
}

@media screen and (max-width: 650px) {
  .test-left {
    min-height: 250px;
  }
  .test-left {
    background-size: cover;
    background-position: center center;
  }
  .test-left img {
    display: none;
  }
  .test-left,
  .test-right {
    width: 100%;
  }
  .test-right-image {
    width: 95px;
  }
  .test-right span {
    line-height: 16px;
  }
  .slick-dots {
    bottom: -10px;
  }
  .trx-works span.trx-works-header {
    font-size: 22px;
    line-height: 30px;
  }
  .trx-works span.rm-main {
    font-size: 18px;
    line-height: 26px;
  }
  .howitworks,
  .safe h2 {
    font-size: 28px;
  }
  .howitworks {
    margin-top: 50px;
  }
  .orange-bar {
    font-size: 18px;
    line-height: 22px;
    padding: 20px 15px;
  }
}

@media screen and (max-width: 575px) {
  .safe-text,
  .safe-digital {
    font-size: 14px;
    line-height: 20px;
  }
  .main .inner-main > div.find-more {
    margin-top: 20px !important;
  }
  .main .inner-main > div {
    line-height: 3rem;
  }
  .orange-bar {
    font-size: 16px;
    line-height: 20px;
  }
  footer .inner-footer {
    padding: 20px 0;
  }
  footer .copyright {
    padding: 0 0 20px;
  }

  .featured {
    padding: 30px 0 25px;
  }
  .featured h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .howitworks,
  .safe h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .safe {
    padding: 40px 0 20px;
  }
  .safe-box {
    padding: 20px 0;
  }
  .safe-title {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .safe-text,
  .safe-digital {
    font-size: 13px;
    line-height: 20px;

    margin: 0 auto;
    display: inline-block;
  }
  .trx-works .trx-works-wrap span.num-label {
    font-size: 30px;
    width: 75px;
    height: 75px;
  }
  .trx-works-wrap .main-text {
    font-size: 14px;
    line-height: 20px;
  }
  .subtrx p {
    font-size: 14px;
    line-height: 22px;
  }
  .howitworks,
  .safe h2 {
    font-size: 20px;
  }
  .trx-works span.rm-main {
    font-size: 15px;
    line-height: 20px;
  }
  .trx-works span.trx-works-header {
    font-size: 18px;
    line-height: 26px;
  }
  .test-left {
    min-height: 150px;
  }
  .test-right-details {
    width: 70%;
  }
  .test-right-content p {
    font-size: 14px;
    line-height: 20px;
  }
  .main-des .rm-main {
    font-size: 16px;
    max-width: 90%;
    margin: 0 auto;
  }
  .main .inner-main .header-text {
    font-size: 24px;
  }
  .find-more .btn-small {
    font-size: 12px;
    padding: 5px 40px;
  }

  .host-item .host-image {
    display: none;
  }
  .host-item .host-right {
    width: 100%;
  }
  .host-item .host-content {
    padding: 20px 20px 10px;
  }
  .host-item {
    margin-bottom: 15px;
  }
}
