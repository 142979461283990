.dashboard__top-title {
  margin-bottom: 16px;
  font-size: 18px;
  word-wrap: break-word;
}

.dashboard__reminder {
  a {
    text-decoration: underline;
    color: #05b6a5;
  }
}
