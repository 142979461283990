.dashboard-component-boxed {
	background-color: #FFF;
	border-radius: 20px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	padding: 1em;
	margin-bottom: 1.25em;

	a {
		font-size: 0.8em;
		display: inline;
		margin-left: 0.3em;
	}
}
