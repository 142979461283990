input[type=text].location-autosuggest {
	color: black;

	&.error {
		color: red;
	}
}

input[type=text].location-autosuggest + .react-autosuggest__suggestions-container {
	background-color: white;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	margin-top: 0;

	.react-autosuggest__suggestion--highlighted {
		background-color: rgba(239, 127, 73, 0.2);
		color: black;
	}

	ul {
		padding: 0.5em 0 1em 0;
		border: 0;
		background: transparent;

		li {
			padding: 0.3em 2em;
			margin: 0;
			background: transparent;
			font-weight: normal;
			//TODO: Remove this once body has been changed to matching font
			font-family: Montserrat, sans-serif;
			font-size: 14px;
			transition: background-color 0.25s linear;

			&:hover {
				background-color: rgba(239,127, 73, 0.2);
				color: black;
			}

			.autosuggest__item > .highlight {
				font-weight: 600;
				color: black;
			}
		}
	}
}
