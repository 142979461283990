.default-modal__body {
  padding: 32px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.default-modal__heading {
  font-size: 30px;
  color: #05b6a5;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.default__modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
}

.default-modal__shown {
  display: block;
  padding-right: 15px;
}
