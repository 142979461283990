.fixed-top {
  top: 10px !important;
}
$primary-color: #ef7f49;
$second-color: #05b6a5;

body {
  background-color: #F8F8F8;
}

footer {
  background-color: #FFF;
}

/*HEADER*/
header {
  border-bottom: 1px solid #f1efef;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
  background: #ffffff;

  .logo {
    img {
      max-height: 48px;
    }
  }
  .nav-link {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 15px 0px !important;
  }
  .login-menu {
    @media (max-width: 976px) {
      justify-content: space-between;
    }
    .btn-browse {
      min-width: 200px;
      margin-bottom: 0px;
      @media (max-width: 990px) {
        min-width: 145px;
        height: 30px !important;
        line-height: 31px !important;
      }
      @media (max-width: 480px) {
        padding: 0px !important;
        min-width: 102px;
      }
    }
    .nav-link {
      margin-left: 0px;
      @media (max-width: 990px) {
        margin-left: 20px;
      }
      @media (max-width: 480px) {
        padding-right: 5px !important;
      }

      &.messagecta {
        position: relative;
        .notifCount {
          position: absolute;
          width: 15px;
          height: 15px;
          line-height: 10px;
          font-size: 12px;
          left: 8px;
          top: 8px;
          background: #ef7f48;
          border-radius: 50%;
          color: #ffffff;
          text-align: center;
          line-height: 13px;

          &.empty {
            display: none;
          }

          @media (max-width: 991px) {
            left: -8px;
            top: 4px;
          }
        }
      }
    }
    @media (max-width: 990px) {
      .navbar-nav {
        display: inline-block !important;
        position: relative;
        .btn-browse {
          padding: 0px !important;
        }
        li {
          display: inline-block !important;
        }
      }
    }
    .fa {
      margin-right: 6px;
    }
  }
  nav {
    float: right;
    @media (max-width: 976px) {
      float: none !important;
    }

    .dropdown-toggle {
      margin-left: 0px;
      padding-left: 0px !important;
    }

    a {
      font-size: 20px;
      color: #555353;
      padding: 0.5em 0.4em !important;
      padding: 0 15px 0 15px !important;
      line-height: 50px;

      &.dropdown-toggle {
        color: $primary-color;
      }
    }
    .fa {
      line-height: 50px;
      @media (max-width: 976px) {
        left: 10px;
      }
    }
    .btn {
      width: 140px !important;
      min-width: 140px !important;
      height: inherit;
      line-height: 32px;
      font-size: 12px !important;
      padding: 0 20px !important;
      line-height: 49px;
      width: auto;
      border: 0;
      @media (max-width: 976px) {
        width: 102px !important;
        min-width: 102px !important;
      }
    }
    .menu-option-get-verified {
      background-color: #05b6a5 !important;
      margin-left: 0px !important;
    }
    .btn-org {
      margin-right: 10px;
      margin-left: 20px;
      @media (max-width: 976px) {
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
    .btn-green {
      margin-right: 1em;
      margin-left: 20px !important;
      color: white;
      @media (max-width: 976px) {
        margin-left: 5px !important;
      }
    }
  }
  .logo {
    padding: 17px 0px 0px;
  }
  .navbar {
    .dropdown-menu {
      padding: 0px;
      @media (max-width: 976px) {
        // background-color: #f1814c;
      }
      .dropdown-item {
        // padding: 15px 0px 12px !important;
        padding: 8px 10px !important;
        line-height: normal;
        &:hover {
          background-color: unset;
          color: $primary-color;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .navbar > a {
      display: block;
      width: 72px;
      height: 30px;
      background-image: none;
      background-size: cover;
      background-position: center center;
      float: left;
      position: relative;
      bottom: 10px;
    }
    
  }
  /****SEARCH****/
  .search {
    position: relative;
    padding-left: 45px;
    @media (max-width: 976px) {
      padding-left: 0px;
    }
    input {
      outline: none;
    }
    input[type='search'] {
      -webkit-appearance: textfield;
      -webkit-box-sizing: content-box;
      font-family: inherit;
    }

    input[type='search'] {
      font-size: 100%;
      border: none;
      -webkit-border-radius: 10em;
      -moz-border-radius: 10em;
      border-radius: 10em;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s;
    }

    input[type='search'] {
      width: 20px;
      padding: 8px;
      color: transparent;
      cursor: pointer;
      &:focus {
        width: 210px;
        border: solid 1px #ccc;
        padding-left: 10px;
        color: #000;
        background-color: #fff;
        cursor: auto;
      }
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      text-indent: 15px;
      height: 35px;
      z-index: 2;
      outline: none;
      color: #999;
      background: transparent;
      border: 1px solid #343434;
      border-radius: 5px;
      @media (max-width: 976px) {
        position: relative;
      }
    }
  }
}
.mobile-main-nav {
  .navbar-collapse {
    z-index: 100;
    background: rgba(239, 127, 73, 0.9);
    position: fixed;
    right: 0;
    left: 0;
    top: 0 !important;
    bottom: 0;
    width: 100vw;
    height: 100vh !important;

    ul {
      padding-top: 60px;
    }
  }
  .navbar-toggler:not(.collapsed) {
    position: fixed;
    z-index: 150;
    right: 15px;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse.collapsing {
    display: none;
  }
  .login-menu ~ .navbar-collapse {
    margin-right: 10px;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: none;
  }
}

/**************MEDIA QUERY**************/
@media (max-width: 1024px) {
  .trx-login .modal-dialog {
    max-width: 93% !important;
  }
}
@media (max-width: 991px) {
  .small-logo {
    padding: 0 7px 0 0;
  }
  /*DESKTOP NAVIGATION*/
  .desktop-main-nav {
    display: none;
  }
  .desktop-main-nav .trx-login .modal {
    display: block !important;
  }
  /*MOBILE NAVIGATION*/
  .mobile-main-nav .navbar-toggler > .close {
    display: inline;
  }

  .mobile-main-nav .navbar-toggler.collapsed > .close,
  .navbar-toggler:not(.collapsed) > .open {
    display: none;
  }
  .navbar-toggler {
    z-index: 150;
  }
}
/**************MEDIA QUERY**************/
@media (min-width: 991px) {
  .mobile-main-nav {
    display: none;
  }
}
nav {
  .dropdown-toggle {
    margin-right: 20px;
  }
  .dropdown-toggle::after {
    content: '\f078';
    font-family: 'fontawesome';
    border-top: 0px;
    padding-top: 0px;
    vertical-align: 0px;
  }
}
.blog-msg {
  padding: 5px;
  i {
    padding: 0px 6px;
  }
}

.how-it-works-nav-item {
  margin-right: -15px;
}

@media (max-width: 991px) {
  header nav .login-menu {
    right: 0;
    position: absolute;
  }
}

.header-profile-dropdown-wrapper {
  cursor: pointer;
}

header nav .profile .dropdown-toggle {
  width: 42px !important;
  min-width: 30px !important;
  height: 42px;
  border-radius: 50px;
  border-color: #a7a5a5;
  color: #a7a5a5;
  background-color: #cecece;
  background-size: cover;
  padding: 0px 21px !important;
  @media (max-width: 480px) {
  }
  @media (max-width: 991px) {
    padding: 0px !important;
    margin-right: 10px;
    width: 30px !important;
    height: 30px;
    padding: 0px !important;
  }
  // margin-right: 0px;
}
.profile .dropdown-toggle:empty::after {
  margin-left: 30px;
  padding-top: 0px;
  @media (max-width: 991px) {
    display: none;
  }
}

/******************HEADER NAV PROFILE ******************************/

header nav .profile {
  a {
    font-size: 18px;
  }
  .dropdown-menu {
    position: absolute;
    left: auto;
    right: 0;
    top: 45px;
    width: 215px !important;
    @media (max-width: 991px) {
      padding: 0;
    }
    ul.header-dropdown-menu {
      padding: 0;
      li {
        display: block !important;
        a.dropdown-item {
          padding: 14px 24px !important;
          @media (min-width: 380px) {
            padding: 5px 24px !important;
          }
          line-height: normal;
          &:hover {
            background-color: unset;
            color: #05b6a5;
          }
        }
      }
    }
    
    .btn {
      min-width: 324px !important;
      text-align: center;
      line-height: 15px;
      height: 42px;
      @media (max-width: 991px) {
        min-width: 165px !important;
      }
      &:hover {
        background: #0ec9b7;
      }
    }
    a {
      color: #555353;
      border-bottom: 1px solid #555353;
      font-size: 17px;
      padding: 15px 0px;
    }
    a:last-child {
      border: none;
      padding-bottom: 0px !important;
    }
  }
}
//authenticated

li.nav-item.dropdown.how-it-works-dropdown .dropdown-toggle {
  margin-right: 2px;
}

li.nav-item-header-browse-button {
  margin-right: 13px;
}

.login-menu {
  .how-it-works-nav-item {
    margin-right: -15px !important;
  }
  .navbar-nav > li:first-child {
    margin-right: 15px;
  }
  @media (max-width: 480px) {
    .msg-nav {
      display: none;
    }
  }
  .navbar-nav li.nav-item a {
    margin-left: 0px;
  }
  .navbar-nav .nav-link {
    line-height: 42px;
    @media (max-width: 991px) {
      line-height: 30px;
    }
  }
  .fa {
    line-height: 42px;
    margin-left: 0px;
    margin-right: 5px;
    height: 42px;
    @media (max-width: 991px) {
      line-height: 30px;
      height: 30px;
    }
  }
}
/******************LOGIN PAGE ******************************/
.trx-login-form {
  font-size: 20px;
  form {
    label {
      letter-spacing: -1px;
      line-height: 29px;
    }
    i {
      color: #ffffff;
      font-size: 28px;
      float: left;
      line-height: 1px;
    }
    .form-control {
      width: 100%;
      height: 60px;
      font-size: 20px;
    }
    input.form-check-input {
      border: 1px solid #444;
      width: 18px;
      height: 18px;
      border-radius: 0;
    }
    input.form-check-input:checked:after {
      content: '\2713';
      font-size: 18px;
      font-weight: bolder;
      line-height: 14px;
      position: absolute;
    }
    .login-opt {
      text-align: center;
      margin: 14% 0px;
      @media (max-width: 480px) {
        margin: 0px 0px;
      }
    }
  }
}

.nav-link--registered {
  border-radius: 30px;
  background-color: #ef7f49;
  color: white;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  width: 140px;

  &:hover {
    color: white;
    background-color: transparentize(#ef7f49, 0.1);
  }
}

.mobile-main-nav {
  .navbar-nav {
    a.button {
      @media (max-width: 990px){
				min-height: 30px;
				line-height: 31px;
			}
    }
  }
}