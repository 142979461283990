.login__modal-body {
  padding: 32px;
  border-radius: 30px;
  position: relative;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.trx-login-form {
  .trx-reg {
    margin-top: 10px;
    text-align: center;

    a {
      color: white;
    }
  }

  .form-control {
    font-size: 14px !important;
    border-radius: 30px;
    height: 54px !important;
  }

  label {
    font-weight: 500;
  }
}

.login-form__heading {
  font-size: 30px;
  color: #555353;
  font-weight: 600;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif !important;
}

.login__modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
}

.login-form__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.public-search__body {
  text-align: center;
  margin-bottom: 180px;

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-family: Montserrat, sans-serif;
  }
  .public-search__buttons {
	margin-top: 1em;
  }
}

.public-search__title {
  color: #ef7f49;
  font-size: 40px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  padding-bottom: 16px;
}

.public-search__login {
  color: #fff;
  padding: 10px 40px;
  line-height: 27px;
  min-width: 165px;
  width: 100%;

  &:hover {
    color: #fff;
  }

  @media screen and (min-width: 768px) {
    margin-left: 20px;
    width: auto;
  }
}

.public-search__register {
  width: 100%;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    width: auto;
    margin-bottom: 0;
  }
}

.modal-content {
  border-radius: 30px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 1.5rem;
}

.hr-text:before {
  content: '';
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  font-size: .8rem;
  padding: 0 .5em;
  line-height: 1.5rem;
  font-weight: 600;
  color: rgba(85, 83, 83, 0.55);
  background-color: #fff;
}
