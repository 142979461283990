.has-error {
  margin-top: 10px;
  color: #ea3b3b;
  font-size: 15px;
}
.checkboxes .has-error {
  margin: 0 0 4px 0;
}

.is-invalid-input {
  border-color: #ea3b3b;
  color: #ea3b3b;
}
$primary-color: #ef7f49;
$second-color: #05b6a5;

.text-org {
  color: $primary-color;
}
select {
  -webkit-appearance: none; // reset default webkit appearance
  -moz-appearance: none; // reset default mozilla appearance
  text-indent: 0.01px; // this hack removes the remaining default arrows in Firefox
  white-space: nowrap; // disallow multiline select values
  text-overflow: ''; // disable text overflow on select values
  outline: none; // remove default active outline
  border: 0; // reset borders
  &::-ms-expand {
    display: none; // remove IE default arrows
  }
}

/*CHECKBOX*/
.form-check-input {
  -webkit-appearance: none; // reset default webkit appearance
  -moz-appearance: none; // reset default mozilla appearance
  border: 0;
}
.form-check-input:focus {
  outline: none;
}
.form-check-input:hover {
  -moz-box-shadow: inset 0 0 4px #888888;
  -webkit-box-shadow: inset 0 0 4px #888888;
  box-shadow: inset 0 0 4px #888888;
}

/*FONT*/

@font-face {
  font-family: 'CircularProBook';
  src: url('/themes/theroomxchange/assets/fonts/circular-book/lineto-circular-pro-book.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'CircularProBold';
  src: url('/themes/theroomxchange/assets/fonts/circular-book/lineto-circular-pro-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'CircularProMed';
  src: url('/themes/theroomxchange/assets/fonts/circular-book/lineto-circular-pro-medium.ttf')
    format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('/themes/theroomxchange/assets/fonts/circular-book/lineto-circular-pro-medium.ttf')
    format('truetype');
  font-weight: medium;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('/themes/theroomxchange/assets/fonts/others/HelveticaNeue-ThinCond.otf')
    format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Gotham';
  src: url('/themes/theroomxchange/assets/fonts/others/Gotham-Bold.otf')
    format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/themes/theroomxchange/assets/fonts/others/Roboto-Regular.otf')
    format('truetype');
  font-weight: medium;
}

body,
html,
p,
ul,
li,
label {
  line-height: 1.5rem;
  font-size: 18px;
  font-weight: normal;
  color: #555353;
  font-family: Montserrat, sans-serif;
}

/*HEADING*/
h1 {
  font-size: 32px;
  font-family: Montserrat, sans-serif;
  letter-spacing: -1px;
  margin-bottom: 35px;
}

h2 {
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
h4 {
  font-size: 16px;
  color: $primary-color;
  text-transform: uppercase;
  margin-bottom: 14px;
}

/*PARAGRAPH*/
.main-text {
  font-size: 24px;
  @media (max-width: 976px) {
    font-size: 20px;
  }
}
.text {
  font-size: 20px;
}

.sub-text {
  font-size: 14px;
}

p {
  margin-bottom: 25px;
  @media (max-width: 976px) {
    margin-bottom: 0px;
  }
}

/*ANCHOR*/
a {
  color: #555353;
  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
  &:active {
    color: $primary-color;
  }
}

/*BUTTON*/
.btn {
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
}

.btn-large {
  min-width: 270px;
  height: 60px;
  line-height: 48px;
  @media (max-width: 976px) {
    min-width: 225px;
    max-width: 100%;
  }
}
.btn-medium {
  min-width: 216px;
  height: 64px;
  @media (max-width: 1280px) {
    min-width: 170px;
  }
  @media (max-width: 976px) {
    min-width: 175px;
  }
}

.btn-small {
  min-width: 200px;
  height: 60px;
  @media (max-width: 1200px) {
    min-width: 142px;
    height: 42px;
    line-height: 1.5rem;
  }
}

.btn-x-small {
  min-width: 100px;
  height: 45px;
  line-height: 1.7rem;
}

.btn-transparent {
  border: 1px solid #555353;
  background-color: #555353;
  color: white;
  &:hover {
    opacity: 0.75;
  }
}

.btn-org {
  background-color: $primary-color;
  &:hover {
    background-color: #e68e64;
  }
}

.btn-green {
  background-color: $second-color;
  &:hover {
    background-color: #0ec9b7;
  }
}

.btn-gray {
  background-color: #555353;
  color: #ffffff;
  &:hover {
    background-color: #747171;
  }
}

.btn-submit {
  font-family: 'Roboto-Regular';
  font-size: 22px;
  text-transform: uppercase;
  width: 440px;
  height: 60px;
  @media (max-width: 976px) {
    width: 200px;
    height: 42px;
    line-height: 1.5rem;
  }
}

/*PAGINATION*/
.trx-pagination {
  text-align: center;
  justify-content: center;

  .pagination {
    justify-content: center;

    ul li {
      display: inline;
      &:active {
        color: $primary-color;
      }
    }

    .page-link {
      border: 0;
      color: #95989a;
    }

    .page-item.active .page-link {
      color: $primary-color;
      background-color: #ffffff;
      border: 0;
    }

    .page-link:hover {
      color: $primary-color;
      background-color: #ffffff;
    }
  }
}

/*SOCIAL MEDIA*/
.social-media ul {
  list-style: none;
  padding: 0;
  li {
    background: $primary-color;
    font-size: 24px;
    display: inline-block !important;
    height: 48px;
    width: 48px;
    line-height: 2.7rem;
    text-align: center;
    border-radius: 40px;
    margin-right: 5px;
    a {
      color: #ffffff;
    }
  }
}

/*COMMON MODE*/
.com-mod {
  margin-bottom: 85px;
  @media (max-width: 976px) {
    margin-bottom: 25px;
  }
}

/*HEADER MODE*/
.header-mod {
  margin-top: 145px;
  @media (max-width: 976px) {
    margin-top: 110px;
  }
}

/*FORM CONTROL*/
.form-control {
  font-size: 14px;
}

.subscribe:hover {
  color: $primary-color;
  background-color: #ffffff;
}

.page-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,0.25);
  background-image: url('/app/themes/theroomxchange/assets/images/loading.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60px 51px;
}

select.form-control {
  background-image: url('/themes/theroomxchange/assets/icons/dropdown.svg');
  background-position: 96% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.rbt-input-hint {
  height: 100%;
  width: 100%;
}

.icon-rounded {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon-rounded-grey {
  @extend .icon-rounded;

  height: 15px;
  width: 15px;
  border: 1px solid #95989a;
  font-size: 10px;
}

.message-request__container {
  display: flex;

  i {
    flex: 0 0 auto;
    margin-left: 4px;
    margin-right: 4px;

    &.fa-info {
      height: 15px;
      width: 15px;
      border: 1px solid #95989a;
      font-size: 10px;
      margin-bottom: 0;
    }

    &.fa-check {
      height: 22px;
      width: 22px;
      color: white;
      background-color: #05b6a5;
    }
  }
}
