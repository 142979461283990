.content {
	margin-top: 125px;
}

.user-dashboard-wrapper {
	font-family: Montserrat, sans-serif;
	font-size: 14px;

	p {
		font-weight: 400;
		font-family: Montserrat, sans-serif;
		font-size: 14px;
	}

	p > a {
		font-size: 1em;
	}

	.link-button {
		border-radius: 5px;
		background-color: $primary-color;
		color: white;
		font-weight: 500;
		border: 0;
		display: inline-block;
		padding: 0.8em 1.5em;

		&:hover {
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
	}

	a.link-button {
		text-decoration: none;
	}

	a, a:not([href]):not([tabindex]) {
		font-weight: 400;
		color: $second-color;
		text-decoration: underline;
		cursor: pointer;
	}

	a:hover, a:not([href]):not([tabindex]):hover {
		text-decoration: none;
	}

	a.cancel-link, a:not([href]):not([tabindex]).cancel-link, a.cancel-link:hover {
		color: #6c757d;
	}

	h3 {
		font-size: 1.6em;
		font-family: Montserrat, sans-serif !important;
		font-weight: 500;
	}

	h4 {
		text-transform: none;
		font-size: 1.4em;
		font-family: Montserrat, sans-serif !important;
		font-weight: 500;
		margin-bottom: 0.2em;
	}

	.bottom-section-wrapper {
		display: flex;
		margin-top: 2em;

		.useful-resources {
			flex: 55%;
			margin-right: 0.5em;
		}

		.bottom-second-col-wrapper {
			flex: 45%;
			margin-left: 0.5em;
		}
		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	.social-icons-strip {
		display: flex;
		justify-content: space-between;
		padding: 0 3em;

		i {
			font-size: 5em;
			position: relative;
			transition: all 0.3s ease-out;

			&:hover {
				transform: translateY(-0.1em);
				opacity: 0.8;
				filter: alpha(opacity=80);
			}
		}

		.fa-facebook-official {
			color: #3A5794;

		}

		.fa-twitter-square {
			color: #1DA1F2;
		}

		.fa-linkedin-square {
			color: #0E76A8;
		}

		.fa-instagram {
			color: #E1306C;
		}
	}

	.podcast-badge-wrapper {
		display: flex;
		justify-content: space-evenly;
		.podcast-listen-badge {
			width: 40%;
			height: auto;
			display: inline-block;
			svg {
				width: 100%;
			}
		}
	}
}
