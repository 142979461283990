$primary-color: #ef7f49;
$second-color: #05b6a5;
$base-text-color: #535353;

/*container*/
.authenticated .container {
	max-width: 1024px !important;
}
.selected {
	color: green !important;
	border: 2px solid green !important;
}
.text-org {
	color: $primary-color;
}
.text-green {
	color: $second-color;
}
.text-blue {
	color: #1a9ed9;
}
.text-gray {
	color: #999999;
}
label {
	font-size: 16px;
	.required {
		&:after{
			content: "*";
			color: #F00;
			position: relative;
			left: 2px;
		}
	}
}
.text-org {
	color: $primary-color;
}
.progress-bar {
	background-color: #05b6a5;
}
.bordertop {
	border-top: 1px solid #e1e1e1;
}
.padding-com {
	padding: 25px;
	@media (max-width: 991px) {
		padding: 15px;
	}
	@media (max-width: 480px) {
		padding: 10px;
	}
}

.user-logo {
	// margin-top: 15px;
}

.user-logo__icon {
	font-size: 14px;
	height: 28px;
	width: 28px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
}

#account-setting {
	padding-top: 5px;
}

.subscribe-success-message {
	font-size: 14px;
	color: green;
}

.subscribe-error-message {
	font-size: 14px;
	color: red;
}

.pro-name {
	word-wrap: break-word;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pro-age {
	font-size: 14px;
}

/*DATE*/
.end-date {
	.react-datepicker-popper {
		right: 27px;
		left: auto !important;
	}
	.react-datepicker__triangle {
		right: 45px;
		left: auto;
	}
}
.trx-about-host-date-picker {
	.react-datepicker-wrapper {
		border-radius: 30px;
		height: 54px;
		font-size: 14px;
		width: 100%;
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		.react-datepicker__input-container {
			width: 100%;
			input {
				width: 100%;
				border: none;
				margin-top: 6px;
				outline: none;
			}
		}
	}
}

.btn-apps {
	min-width: 118px;
	height: 42px;
}
.block-border {
	border: 1px solid #f1f1f1;
	@media (max-width: 768px) {
		margin-bottom: 15px;
	}
}
.block-title {
	color: $primary-color;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: 5px;
	@media (max-width: 480px) {
		margin-bottom: 0px;
	}
}

/** Improvements to Edit Profile page form-control **/
.trx-abt-section {
	.trx-abt-wrap {
		margin-bottom: 32px;
		padding: 25px;
		background-color: #FFF;
		border-radius: 20px;
		overflow: hidden;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		.card-body{
			padding: 22px 15px 26px 15px;
		}
		textarea {
			padding: 0.975rem 0.95rem;
		}
	}
}

/** Payment popup total invoice message key**/
.payment-popup-totalinvoice-message {
	.margin-top-12 {
		margin-top: 12px;
	}
	.payment-message-key {
		width: 160px;
		display: inline-block;
		font-weight: bold;
		@media (max-width: 688px) {
			display: block;
			font-size: 15px;
		}
	}
	.payment-message-value {
		@media (max-width: 688px) {
			font-style: italic;
		}
	}
	.monthly-fee-message-container {
		color: #555353;
		font-size: 14px;
		@media (max-width: 688px) {
			margin-button: 10px;
		}
	}
}

@media (max-width: 768px) {
	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 1.18rem !important;
	}
}

.trx-custom-react-datepicker {
	label {
		display: block;
	}
	.react-date-picker-trx {
		display: block;
	}

	.react-date-picker__button {
		border-radius: 30px;
		height: 54px;
		font-size: 14px;
		width: 100%;
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		.react-date-picker__button__input {
			.react-date-picker__button__input__input {
			}
			.react-date-picker__button__input__divider {
			}
		}
	}
	.react-date-picker__calendar--open {
		z-index: 100;
	}
}

.accountPage {
	@media (min-width: 440px) {
		.react-date-picker__calendar {
			left: -71px;
		}
	}
	.com-mod {
		margin-bottom: 0px;
	}
	.form-signup {
		overflow: visible;
	}
	.acc-form {
		.acc-top-form {
			overflow: visible;
		}
	}
	.modal-content {
		p {
			margin-bottom: 0px !important;
		}
	}
}

/*NAV-TABS*/
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border: 0px;
}

.nav-tabs .nav-link {
	border: 0px;
}
/**TOP-TITLE**/
.top-title {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 36px;
	word-wrap: break-word;
	@media (max-width: 1024px) {
		margin: 0px 0px 10px;
		line-height: 30px;
	}
	@media (max-width: 768px) {
		margin: 0px 0px 10px;
		line-height: 30px;
	}
}

/**SEARCH**/
.search-wrap {
	background: #f8f8f8;
	text-align: center;
	// padding: 36px 0px;
	.find-title {
		font-size: 20px;
		margin-bottom: 20px;
	}
	.react-autosuggest__container {
		.react-autosuggest__suggestion {
			padding: 5px 24px 5px 24px;
			&.react-autosuggest__suggestion--highlighted {
				background-color: rgba(239, 127, 73, 0.2);
				color: black;
			}
		}
		label {
			display: none;
		}
		.autosuggest__item {
			text-align: left;
		}
	}
	.btn-large {
		min-width: 270px !important;
		@media (max-width: 1200px) {
			width: 270px;
			margin-top: 15px;
		}
	}
	.form-inline {
		.form-control {
			margin-right: 20px;
			width: 270px;
			display: inherit;
			height: 42px;
			line-height: 26px;
			@media (max-width: 1200px) {
				margin-bottom: 0px;
				margin-right: 0px;
			}
			@media (max-width: 768px) {
				margin-bottom: 0px;
				margin-right: 0px;
			}
		}
	}
	.btn {
		@media (max-width: 1024px) {
			.btn {
				margin-top: 15px !important;
			}
		}
	}
}

/****FIND WRAP******/
.find-wrap {
	background: #f8f8f8;
	text-align: center;
	// @media (max-width: 768px) {
	//   padding: 35px 0px;
	// }
}
/******************VERIFY CODE ******************************/
.verify-code {
	background: #f3f1e7;
	padding: 15px 0px 0px;
	text-align: center;
	.btn-x-small {
		width: 105px;
		height: 38px;
		line-height: 0px;
		font-size: 12px;
	}
}

/******************LOGIN PAGE ******************************/
.trx-login-form {
	font-size: 20px;
	form {
		label {
			letter-spacing: -1px;
			line-height: 29px;
		}
		i {
			color: #ffffff;
			font-size: 28px;
			float: left;
			line-height: 1px;
		}
		.form-control {
			width: 100%;
			height: 60px;
			font-size: 20px;
		}
		input.form-check-input {
			border: 1px solid #444;
			width: 18px;
			height: 18px;
			border-radius: 0;
		}
		input.form-check-input:checked:after {
			content: '\2713';
			font-size: 18px;
			font-weight: bolder;
			line-height: 14px;
			position: absolute;
		}
		.login-opt {
			text-align: center;
			margin: 14% 0px;
			@media (max-width: 480px) {
				margin: 0px 0px;
			}
		}
	}
}

/*FORM COMMON*/
.trx-signup-form {
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
	a:not(.orange-button):not(.dropdown-item) {
		color: $primary-color;
		display: inline-block;
	}
}

.international-user-modal {
  .modal-header {
    .top-title {
      margin-bottom: 0px;
    }
  }
  .model-heading-subtext {
    text-align: left;
    margin-bottom: 31px;
    font-size: 16px;
    color: rgba(67, 66, 65, 0.65);
    font-family: 'Montserrat', sans-serif !important;
    line-height: 1.75rem;
  }
}

.profile-complete-motivation-text {
	margin-bottom: 22px;
	p {
		color: #ef7f49;
		margin-bottom: 0;
	}
}

.textAlignLeft {
	text-align: left;
}

/*  FORM*/

.trx-signup-form {
	.form-group {
		margin-bottom: 36px;
		@media (max-width: 991px) {
			margin-bottom: 20px;
		}
	}
	.btn-org-border {
		border: 1px solid $primary-color;
	}
}

/****************** TOOLTIP ******************************/

.tooltip-inner {
	background-color: #ffffff !important;
	border: 1px solid #a6a4a4;
	color: #555353;
	max-width: 350px;
	padding: 10px;
	text-align: left;
}
.tooltip {
	max-width: 95%;
}
#password .tooltip-inner {
	width: 30%;
}
/*TRX SIGN UP FORM*/
.trx-signup-form .fa {
	color: #95989a;
}
/****************** GUEST SIGNUP FORM ******************************/

.trx-guest-signup {
	.pri-xh {
		width: 29px;
		height: 29px;
		border-radius: 0%;
		border: 2px solid #95989a;
		text-align: center;
		color: #f4976a;
	}
}
.trx-com-wrap.com-mod {
	margin-bottom: 42px;
}
.trx-com-wrap.header-mod {
	margin-top: 125px;
	@media (max-width: 991px) {
		margin-top: 105px;
	}
	@media (max-width: 480px) {
		margin-top: 75px;
	}
}

#info-gap {
	margin: -8px 5px;
}

.trx-com-wrap {
	font-size: 14px;
	line-height: 25px;
	.pro-title {
		margin-bottom: 15px;
		// font-family:'CircularProBold';
		font-size: 16px;
		span {
			font-family: 'Montserrat', sans-serif;
		}
	}
	.btn {
		font-size: 13px;
		height: 42px;
		// justify-content: center;
		line-height: 28px;
		text-transform: capitalize;
		// margin-top: 20px;
		@media (max-width: 991px) {
			margin-top: 10px;
		}
	}

	#host-task {
		background: #ffffff;
		float: none;
		font-size: 9px;
		line-height: 18px;
		height: 15px;
		width: 15px;
		border: 1px solid #95989a;
		color: #95989a;
		margin: 8px 3px;
	}
}

/****************** GUEST PROFILE ******************************/

.trx-gt-profile {
	font-size: 18px;
	.btn-org {
		margin-top: 50px;
	}
	#profile {
		.info-title {
			color: #444444;
			font-weight: normal;
			margin-bottom: 45px;
		}
		.b-title,
		.s-title {
			color: #c1c1c1;
			font-size: 20px;
			font-weight: normal;
			text-transform: uppercase;
			// font-family: 'Roboto-Regular';
			margin-bottom: 7px;
		}
		.form-check {
			margin-bottom: 5px;
		}
	}
	.pro-badge {
		border: 2px solid #f1f1f1;
		padding: 15px;
		font-family: 'CircularProMed';
		font-size: 18px;
		span {
			font-size: 18px;
		}
		.pro-digi-id {
			margin-bottom: 25px;
		}
		.pro-id {
			margin-right: 10px;
			float: left;
		}
		.pro-digi {
			margin-bottom: 55px;
		}
		.form-check-input {
			width: 18px;
			height: 18px;
		}
		.form-check-label {
			margin-left: 10px;
			vertical-align: middle;
		}
		.id-des {
			overflow: hidden;
		}
	}
	.pro-inf {
		.pro-icon {
			float: right;
			margin-top: 30px;
		}
		.pro-img-wrapper {
			margin-bottom: 25px;
			overflow: hidden;
			width: 12rem;
			img {
				height: 123px;
				width: 123px;
				border-radius: 50%;
			}
			.pro-img {
				float: left;
				img {
					border-radius: 50%;
					width: 123px;
				}
			}
		}
		dt {
			color: #c1c1c1;
			font-size: 18px;
			font-weight: normal;
			text-transform: uppercase;
			// font-family: 'Roboto-Regular';
			margin-bottom: 7px;
		}
		dl {
			color: #000000;
			font-size: 18px;
			// font-family: 'Roboto-Regular'
		}
		dd {
			margin-bottom: 55px;
		}
		span {
			font-size: 14px;
			line-height: 1px;
		}
		i {
			background: #ffffff;
			font-size: 20px;
			display: inline-block;
			height: 40px;
			width: 40px;
			line-height: 40px;
			text-align: center;
			border: 1px solid #95989a;
			border-radius: 50%;
			color: #95989a;
		}
		.fa {
			color: #95989a !important;
		}
	}
	.table-bordered {
		border: 2px solid #f1f1f1;
	}
}
#pass .btn-org {
	margin-top: 55px;
	font-size: 20px;
	height: 50px;
	width: 150px;
}

/****************** BROWSE LIST ******************************/

.browse-list {
	.browse-wrap .btn {
		width: 100%;
		margin-top: 10px;
	}
	.browse-title {
		font-size: 24px;
		margin-bottom: 20px;
		overflow: hidden;
		span {
			font-size: 16px;
			color: #999999;
			margin-left: 20px;
		}
		i {
			font-size: 22px;
			color: #b5b4b4;
			&.active {
				color: #ef7f49;
			}
		}
		.fa-map-marker {
			float: left;
		}
		.btn {
			height: 42px !important;
		}
	}
	.form-signup {
		display: inline-block;
	}

	.list-block {
		margin-bottom: 38px;
		font-size: 20px;
		.block-wrap {
			height: 140px;
			overflow: hidden;
		}
		.list-img-wrap {
			position: relative;
			border: 1px solid #95989a;
			margin-bottom: 22px;
			width: 100%;
			.list-status {
				position: absolute;
				color: #ffffff;
				font-size: 14px;
				padding: 25px 0px;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
				@media (max-width: 1024px) {
					padding: 10px;
				}
			}
			.status-org {
				background: $primary-color;
			}
			.status-blue {
				background: #1a9ed9;
			}
			.status-gray {
				background: #999999;
			}
		}
		.pro-name,
		.pro-location {
			margin-bottom: 7px;
		}
		.pro-location {
			font-size: 16px;
		}
		.pro-name {
			text-transform: capitalize;
			font-size: 20px;
		}
		.pro-det {
			font-size: 16px;
		}
	}
}

/************** BROWSE DETAIL ***************/
.browse-detail, .trx-abt-section {
	.back-link {
		cursor: pointer;
		margin-bottom: 20px;
		display: flex;
		align-items: center;

		i {
			border: 0px;
			color: $primary-color;
			display: inline;
			font-size: 20px;
			line-height: 25px;
			margin-right: 8px;
			margin-left: 8px;
		}
	}

	@media (max-width: 768px) {
		.nav-tabs {
			display: none;
		}
	}
	.modal-backdrop {
		// overflow-x: hidden;
		overflow: auto;
		.btn {
			width: 216px;
		}
	}
}
/************** GUEST ABOUT SECTION ***************/

/************** GUEST DASHBOARD *******************/
.trx-com-wrap {
	// padding:15px;
	overflow: visible;
	.dash-acc {
		.box {
			overflow: hidden;
		}
		.top-inner {
			background: $primary-color;
			height: 104px;
			margin-bottom: 8px;
			padding-top: 16px;

			@media (max-width: 991px) {
				height: auto;
			}

			.img-wrapper > div {
				@media (min-width: 768px) {
					position: absolute;
					// bottom: -48px;
				}
			}
			.user-profile-info {
				margin-top: 16px;
				.user-name {
					font-size: 18px;
					font-weight: 600;
				}
				.user-role {
					font-size: 15px;
					font-weight: 400;
				}
				@media (max-width: 660px) {
					text-align: center;
				}
			}
		}
		.account-info-note {
			font-size: 13px;
			font-style: italic;
			@media (max-width: 660px) {
				text-align: center;
			}
		}
		dd {
			word-wrap: break-word;
			width: 92%;
		}

		.dash-basic {
			overflow: hidden;
			.btn {
				margin-right: 10px;
				@media (max-width: 768px) {
					margin-right: 0px;
				}
			}
		}
	}
	.dash-rt {
		.btn {
			text-align: center;
		}
		.btn-green {
			margin-right: 10px;
			@media (max-width: 768px) {
				margin-bottom: 10px;
			}
		}
	}
	.dash-title {
		font-size: 30px;
	}
	textarea {
		height: 160px !important;
	}
	.btn-gray {
		margin-right: 12px;
	}
	table {
		width: 100%;
		margin: 20px 0px;
	}
	.d-id {
		margin-right: 10px;
		float: left;
	}
	.host-digi {
		margin-bottom: 55px;
	}
	.id-des {
		overflow: hidden;
		// font-size: 12px;
		line-height: 20px;
		@media (max-width: 768px) {
			margin-right: 21px;
		}
		@media (max-width: 768px) {
			margin-bottom: 15px;
		}

		span {
			font-size: 18px;
			font-family: 'Montserrat', sans-serif !important;
			margin-bottom: 8px;
			display: inline-block;
		}
	}

	/*BADGE*/
	.badge-block {
		margin-bottom: 45px;
		@media (max-width: 660px) {
			margin-bottom: 20px;
		}
		.btn {
			margin-top: 0px !important;
		}
		.btn-gray {
			margin-right: 0px !important;
		}
		@media only screen and (min-width: 660px) and (max-width: 768px) {
			.btn-badge {
				padding: 0px;
			}
		}
		#digitalid-verify .btn {
			margin-right: 0px !important;
			float: right;
			@media (max-width: 660px) {
				margin-right: 12px !important;
			}
		}
		@media (max-width: 768px) {
			.btn {
				float: right;
			}
		}

		.verify_page_digital_text {
			a {
				color: #ef7f49;
			}
		}
	}
	.badge-des {
		margin-bottom: 39px;
		overflow: hidden;
		@media (max-width: 976px) {
			margin-bottom: 35px;
		}
	}
	ul.nav-tabs {
		border: 0px;
		margin-bottom: 36px;
		@media (max-width: 991px) {
			margin-bottom: 25px;
		}
		@media (max-width: 480px) {
			margin-bottom: 15px;
		}
		li {
			.nav-link:not(.active) {
				@media (max-width: 768px) {
					display: none !important;
				}
			}
		}
	}
	ul {
		@media (max-width: 768px) {
			margin-bottom: 30px;
			display: inline-block;
		}
		li {
			.nav-link {
				color: #999999;
				font-size: 22px;
				padding-left: 0px;
				padding-right: 0px;
				margin: 0px 40px 0px 0px;
			}
			.nav-link.active {
				color: #999999;
				border: 0px;
				padding-bottom: 5px;
				border-bottom: 2px solid $primary-color;
				@media (max-width: 768px) {
					padding-bottom: 2px;
					// border-bottom:2px solid $primary-color;
				}
			}
		}
	}
	.pro-nav ul {
		list-style: none;
		display: inline-flex;
		padding: 0px;
		margin-bottom: 36px !important;
		@media (max-width: 991px) {
			margin-bottom: 25px !important;
		}
		@media (max-width: 480px) {
			margin-bottom: 15px !important;
		}
		&:empty {
			margin: 0px;
			display: none;
		}
		a {
			font-size: 20px !important;
		}
	}
	.info-title {
		color: #444444;
		font-weight: normal;
		margin-bottom: 45px;
		font-size: 18px;
		font-family: 'CircularProMed';
	}
	.describe-first {
		margin-bottom: 30px;
		font-size: 20px;
	}
	.dash-abt ul {
		padding: 0px;
		margin-bottom: 35px;
		li {
			list-style: none;
			margin-bottom: 15px;
			font-size: 16px;
			text-transform: capitalize;
			font-family: 'CircularProMed';
			&:before {
				background: $primary-color;
				border-radius: 50%;
				content: '\f00c'; /* FontAwesome Unicode */
				font-family: FontAwesome;
				display: inline-block;
				font-size: 16px;
				color: #fff;
				height: 28px;
				width: 28px;
				text-align: center;
				margin-right: 5px;
			}
		}
	}
	.form-check {
		margin-bottom: 5px;
	}
	.form-check-label {
		margin-left: 10px;
		vertical-align: middle;
	}
	.id-des {
		overflow: hidden;
	}
	dt {
		color: $primary-color;
		font-size: 16px;
		font-weight: 500;
	}
	dd {
		margin-bottom: 15px;
	}
}
.dash-acc {
	.host-icon {
		float: right;
		margin-top: 30px;
	}
}
@media (max-width: 660px) {
	.img-wrapper {
		margin-left: auto;
		margin-right: auto;
	}
}
.img-wrapper {
	position: relative;
	img {
		border-radius: 50%;
	}
	i {
		background: $second-color;
		border: 0px;
		color: #ffffff;
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		margin: 0;
		border-radius: 12px;
		@media (max-width: 480px) {
			right: 13px;
		}
	}
}
.fa-pencil {
	margin-right: 2px;
}
.fa {
	color: #95989a;
}
.table-bordered {
	border: 2px solid #f1f1f1;
}
p.verify-empty {
	color: #05b6a5;
	margin-bottom: 0px;
}
.badges-wrap {
	span {
		width: 30px;
		display: inline-block;
		overflow: hidden;
		height: 30px;
		margin-right: 10px;
		color: #fff;
		&.digital_id {
			background: url('../assets/icons/digital_id_logo.svg');
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.police_check {
			display: none;
		}
	}
}
.pro-name .badges-wrap {
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
	height: 30px;
}

/*PROGRESS BAR*/
.pre-bar {
	margin: 10px 0px 30px;
	label {
		font-size: 14px;
	}
}

/********** FORM-MOD *************/
.trx-com-wrap {
	@media (max-width: 767px) {
		margin-bottom: 25px;
	}
	.form-mod {
		margin-bottom: 5px;
		@media (max-width: 480px) {
			margin-bottom: 0px;
		}
	}
	p {
		font-size: 14px;
		line-height: 22px;
	}
	.com-mod {
		margin-bottom: 42px;
	}
}

/**********  SECTION ****************/
.trx-abt-section {
	.pro-block-title {
		margin-bottom: 17px;
		font-size: 20px;
	}
	.section-about {
		.row {
			> div.col-sm-6 {
				@media screen and (min-width: 576px) {
					&:nth-child(1) {
						padding-right: 32px;
					}
					&:nth-child(2) {
						padding-left: 32px;
					}
				}
			}
		}
	}
	.btn-org {
		margin-right: 12px;
		@media (max-width: 768px) {
			margin-right: 10px;
			padding: 5px;
		}
	}

	.form-mod {
		@media (max-width: 768px) {
			margin-bottom: 0px;
		}
	}

	/*ACCORDION*/

	.card {
		border: none;
	}
	.card-body {
		padding: 12px 0px 42px;
		.xh-offer {
			font-family: 'Montserrat', sans-serif;
			font-weight: 500;
			font-size: 16px;
		}
	}
	.card-header {
		background-color: #ffffff;
		padding: 0px;
		h2 {
			margin: 0px;
			padding: 0 15px 5px 15px;
			font-size: 18px;
			font-family: 'Montserrat', sans-serif;
			line-height: 30px;
			text-transform: capitalize;
			display: flex;
			justify-content: space-between;

			&:after {
				color: #999999;
				content: '\F105';
				font-family: 'FontAwesome';
				float: right;
			}
			.card-header__left{
				font-size: 18px;
				font-weight: 500;
			}

			.fa-check {
				background: $primary-color;
				float: left;
				border: none;
				font-size: 12px;
				color: #fff;
				height: 20px;
				width: 20px;
				margin-right: 8px;
			}

			.fa-close {
				background: $second-color;
				float: left;
				border: none;
				font-size: 12px;
				color: #fff;
				height: 20px;
				width: 20px;
				margin-right: 10px;
			}
		}
		[aria-expanded='true'] {
			h2 {
				&:after {
					color: $primary-color;
					content: '\F107';
					font-family: 'FontAwesome';
				}
			}
		}
	}

	@media (max-width: 480px) {
		.abt-btn {
			margin-bottom: 25px;
		}
	}
	
	label {
		font-size: 14px;
		font-family: 'Montserrat', sans-serif;
		margin-bottom: 0px;
		display: inline-block !important;
	}
	.col-lg-5 {
		padding-right: 0;
		padding-left: 0;
	}
	.pro-other {
		span {
			position: relative;
			display: inline-block;
			margin-right: 12px;
			margin-bottom: 12px;
			cursor: pointer;
			i {
				position: absolute;
				right: -7px;
				top: -7px;
				font-size: 14px;
				color: #ffffff;
				background-color: #07b6a5;
				border: 0px;
				padding: 4px 5px;
				border-radius: 16px;
			}
		}
	}
	.pro-upload {
		overflow: hidden;
		margin-bottom: 22px;
		.img-wrapper {
			float: left;
			i.fa-plus{
				position: absolute;
				font-size: 14px;
				color: #ffffff;
				background-color: #07b6a5;
				border: 0px;
				padding: 4px 5px;
				border-radius: 16px;
				cursor: pointer;
			}
		}
	}
	.upload-des {
		margin-bottom: 25px;
	}
	.upload-top-des {
		margin-bottom: 25px;
	}
	.upload-photo {
		margin: 25px 0px 20px;
		text-align: center;
		border: 1px solid #d9d9d9;
		padding: 100px 150px;
		border-radius: 25px;
		@media (max-width: 991px) {
			padding: 20px;
		}
		.file-drop-target {
			margin-top: 20px;
		}
	}
	.upload-text {
		padding: 50px 20px;
		overflow: hidden;
		font-size: 16px;
		@media (max-width: 480px) {
			padding: 20px 10px;
		}
		@media (max-width: 320px) {
			padding: 12px 10px;
		}
	}
}

/*ACCORDION*/
.trx-com-wrap {
	.card {
		border: none;
		margin-bottom: 25px;
	}
	.card-header {
		background-color: #ffffff;
		border-bottom: 1px solid $primary-color;
		padding: 0px;
	}
	.card-body {
		color: #555353;
		font-size: 18px;
		padding: 22px 0px 26px;
		@media (max-width: 768px) {
			padding-bottom: 0px;
		}
	}
	a {
		text-transform: none;
		color: #555353;
	}
	a:hover {
		text-decoration: none;
	}
}
//Signup overlay
.trx-signup-form {
	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
		.body {
			padding: 30px;
			background-color: #ffffff;
			margin-top: 30px;
			button {
				display: inline-block;
				margin-bottom: 10px;
				margin-left: 0 !important;
				&:first-child {
					margin-right: 20px;
				}
			}
		}
	}
}

/*COMMON BLOCK*/
/*RT BLOCK*/
.rt-badge ul {
	padding: 0px;
	li {
		list-style: none;
		margin-bottom: 15px;
		font-size: 18px;
		text-transform: capitalize;
		font-family: 'CircularProMed';
	}
}
.rt-block {
	ul {
		padding: 0px;
		margin-bottom: 35px;
		li {
			list-style: none;
			margin-bottom: 15px;
			font-size: 18px;
			text-transform: capitalize;
			font-family: 'CircularProMed';
			&:before {
				background: $primary-color;
				border-radius: 50%;
				content: '\f00c'; /* FontAwesome Unicode */
				font-family: FontAwesome;
				display: inline-block;
				font-size: 16px;
				color: #fff;
				height: 28px;
				width: 28px;
				text-align: center;
				margin-right: 5px;
			}
		}
	}
}

/***************** VIEW PROFILE ****************/
.pro-wrap {
	padding: 0px;
	overflow: hidden;
	.pro-name {
		font-size: 22px;
		padding: 15px 30px;
		text-transform: capitalize;
		img {
			margin-left: 15px;
		}
	}
	.pro-img-wrap {
		margin-bottom: 24px;
	}
	.pro-location {
		margin-bottom: 40px;
	}
	ul {
		padding: 0px;
		li {
			list-style: none;
			margin-bottom: 8px;
			font-size: 14px;
		}
		li:last-child {
			margin-bottom: 20px;
		}
	}
	dt {
		color: $primary-color;
	}
	dd {
		// font-size: 18px;
		margin-bottom: 15px;
	}
	.pro-desc {
		margin-bottom: 30px;
		overflow-wrap: break-word;
		@media (max-width: 991px) {
			margin-bottom: 10px;
		}
	}
	.view-profile-rt {
		padding-left: 10px;
		@media (max-width: 991px) {
			padding-left: 15px;
			border-top: 1px solid #f1f1f1;
		}
		ul {
			margin-bottom: 0px;
		}
		li {
			font-size: 13px;
			span {
				font-family: 'Montserrat', sans-serif;
				font-weight: 700;
				margin-right: 6px;
			}
		}
	}
	.view-profile-lt {
		border-right: 1px solid #f1f1f1;
		.pro-lt-block {
			// padding: 10px 25px;
		}
	}
	.img-view-wrap {
		position: relative;
		margin-bottom: 30px;
		@media (max-width: 768px) {
			margin-bottom: 55px;
		}
		@media (max-width: 480px) {
			margin-bottom: 55px;
		}
		.button {
			position: absolute;
			bottom: 16px;
			left: 25px;
		}
		.pro-img {
			position: absolute;
			bottom: -50px;
			right: 40px;
			width: 123px;
			@media screen and (max-width: 991px) {
				bottom: -33px;
			}
			@media screen and (max-width: 320px) {
				right: 0px;
			}
			img {
				border-radius: 50%;
			}
		}
	}
	.top-pri > span {
		margin-right: 10px;
		margin-bottom: 10px;
		display: inline-block;
		// font-size: 18px;
	}
	.top-pri span.pri-num {
		background: #ffffff;
		display: inline-block;
		height: 28px;
		width: 28px;
		justify-content: middle;
		text-align: center;
		border: 1px solid #95989a;
		border-radius: 50%;
	}
	// .pro-desc{
	//   height: 475px;
	// }
}
.fa-question {
	font-size: 17px !important;
	height: 22px !important;
	width: 22px !important;
	line-height: 20px !important;
}
@media (max-width: 768px) {
	.register-intro-text {
		text-align: justify;
		a:not(.btn) {
			color: $primary-color;
		}
	}
}

/**MODAL*/
/**MODAL*/
.trx-com-wrap {
	.modal-backdrop {
		background-color: rgba(0, 0, 0, 0.6);
	}
	.modal.fade .modal-dialog {
		height: 100px;
		border-radius: 5px;
	}
	.modal-content {
		border: 1px solid #f2f0ef;
		max-height: 90vh;
		overflow: auto;
		text-align: center;
		h3 {
			color: $primary-color;
			margin-bottom: 10px;
			font-size: 24px;
		}

		.modal-header {
			border-bottom: 0px;
			padding: 20px;
		}
		.modal-body i {
			position: relative !important;
			vertical-align: top;
			margin-right: 10px;
			border: 0px;
			background: $second-color !important;
			color: #ffffff !important;
			display: inline-flex;
		}
		.modal-footer {
			border-top: 0px;
			margin: 0px auto 0px;
			text-align: center;

			> div {
				width: 100%;
			}

			.block-on-sm {
				@media (max-width: 480px) {
					display: block;
				}
			}

			.btn-org {
				margin-right: 10px;
			}
		}
		.btn-x-small {
			@media (max-width: 480px) {
				width: auto !important;
			}
		}
		.btn {
			width: auto;
			margin: 0 auto;
			@media (max-width: 480px) {
				width: 265px;
				max-width: 100%;
			}
		}
	}
}

.autohide {
	-moz-animation: cssAnimation 0s ease-in 5s forwards;
	/* Firefox */
	-webkit-animation: cssAnimation 0s ease-in 5s forwards;
	/* Safari and Chrome */
	-o-animation: cssAnimation 0s ease-in 5s forwards;
	/* Opera */
	animation: cssAnimation 0s ease-in 5s forwards;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
@keyframes cssAnimation {
	to {
		width: 0;
		height: 0;
		overflow: hidden;
		padding: 0;
		border: 0;
		margin: 0;
	}
}
@-webkit-keyframes cssAnimation {
	to {
		width: 0;
		height: 0;
		overflow: hidden;
		padding: 0;
		border: 0;
		margin: 0;
	}
}

.top-pri > div {
	display: inline-block;
	float: left;
}

/**/
#cropperwrap {
	background: #f3f3f3;
	> div {
		margin: 0 auto;
	}
}
/*signup form p*/
.trx-signup-form p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;
}

.trx-signup-form .btn.international {
	// width: auto;
	// max-width: 100%;

	@media (max-width: 768px) {
		margin-top: 0px;
	}
}
.trx-signup-form .not-ready-wrap {
	margin-top: 10px;
	font-size: 14px;
}

.sign-in a.reset-link {
	display: block;
	color: #ef7f49;
	font-size: 14px;
	text-decoration: underline;
}

.sign-in a.disable-link {
	color: #ef7f49;
	font-size: 14px;
	text-decoration: underline;
}

/**CONECT MODAL***/
.connect-modal {
	i {
		position: relative;
		background: #fff;
		color: #999;
		margin-right: 0px !important;
		line-height: 30px !important;
	}
	.modal-content {
		text-align: left;

		.modal-body {
			padding-top: 0px !important;
		}

		h3 {
			font-size: 24px;
		}
		p {
			margin-bottom: 10px !important;
			line-height: 24px !important;
		}
		form {
			.form-wrap {
				border: 10px solid #f9f9fa;
				padding: 25px 25px 10px;
			}
			.control-label {
				text-transform: uppercase;
				font-size: 14px !important;
				font-family: 'Montserrat', sans-serif !important;
				margin-bottom: 5px !important;
			}
			.form-title {
				font-size: 14px !important;
				font-family: 'Montserrat', sans-serif;
				margin-bottom: 10px;
				color: #38344d;
			}
			textarea {
				height: 50px !important;
			}
			textarea.form-control {
				border: 0px;
				border-radius: 0px;
				height: auto;
				padding: 0px;
			}
		}
	}
}

.register-page {
	.btn-signup-role {
		@media (max-width: 768px) {
			font-size: 12px;
			width: 155px !important;
			margin-right: 5px;
			height: auto;
			margin-top: 0px;
			height: 40px !important;
		}
		@media (max-width: 420px) {
			font-size: 10px;
			white-space: normal;
			width: 82px !important;
			margin-right: 4px;
			line-height: 12px;
			height: 40px !important;
			font-family: 'Montserrat, sans-serif';
		}
		@media (min-width: 420px) and (max-width: 597px) {
			font-size: 11px;
			width: 130px !important;
			margin-right: 3px;
			height: 40px !important;
			padding: 7px;
		}
	}
}

.register-page {
	.btn-signup-role {
		@media (max-width: 768px) {
			font-size: 12px;
			width: 155px !important;
			margin-right: 5px;
			height: auto;
			margin-top: 0px;
			height: 40px !important;
		}
		@media (max-width: 420px) {
			font-size: 10px;
			white-space: normal;
			width: 82px !important;
			margin-right: 4px;
			line-height: 12px;
			height: 40px !important;
			font-family: 'Montserrat', sans-serif;
		}
		@media (min-width: 420px) and (max-width: 597px) {
			font-size: 11px;
			width: 130px !important;
			margin-right: 3px;
			height: 40px !important;
			padding: 7px;
		}
	}
}

/*************MESSAGE************/
.msg-page {
	.padding-com {
		padding: 10px;
	}
	.modal-backdrop {
		.modal-content {
			border: 1px solid #fbf9f9;
			.xch-text {
				color: $primary-color;
				font-size: 16px;
			}
			p {
				font-size: 14px;
			}
			.modal-footer {
				display: table;
				.btn-gray {
					margin-right: 10px;
				}
				@media only screen and (min-width: 600px) and (max-width: 768px) {
					.btn {
						width: none;
					}
				}
			}
		}
		.padding-com {
			padding: 25px;
		}
	}
	.bg-gray {
		background-color: #f7f7f7;
		border-top: 1px solid #f1f1f1;
		border-bottom: 1px solid #f1f1f1;
	}
	@media only screen and (min-width: 600px) and (max-width: 768px) {
		.msg-lt {
			max-width: 20%;
			flex: 0 0 20%;
		}
		.msg-md {
			max-width: 45%;
			flex: 0 0 45%;
		}
		.msg-rt {
			max-width: 35%;
			flex: 0 0 35%;
		}
	}
	textarea {
		border-top: 1px solid #f1f1f1 !important;
		border: 0px;
		width: 100%;
		height: 107px !important;
		font-size: 14px;
		padding: 15px 25px;
	}
	p {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		.btn-apps {
			//width: 93px !important;
		}
	}
	.msg-wrap {
		font-size: 12px;
		max-height: 70vh;
		overflow: auto;
		@media (max-width: 480px) {
			.top-title {
				display: none;
			}
		}
		form {
			@media (max-width: 768px) {
				margin-bottom: 0px;
			}
			select.form-control {
				border-radius: 0px !important;
				width: 119px;
				height: 30px !important;
				font-size: 12px;
				@media (max-width: 768px) {
					width: 100%;
				}
				@media (max-width: 480px) {
					width: 100%;
					float: left !important;
				}
			}
			.form-group {
				@media (max-width: 768px) {
					margin-bottom: 0px;
				}
			}
		}
		.form-control {
			width: 270px;
			max-width: 100%;
			&.search {
				height: 42px;
				line-height: 26px;
			}
			@media (max-width: 768px) {
				width: 100%;
				height: 40px;
				font-size: 10px;
				padding: 5px;
			}
			@media (max-width: 480px) {
				width: 55%;
				height: 32px;
				float: right;
			}
		}
		ul {
			padding-left: 25px;
			margin-bottom: 0px !important;
			@media (max-width: 768px) {
				display: none;
			}
			.nav-link {
				font-size: 16px !important;
				&:hover {
					border: 0px !important;
				}
			}
			.nav-link.active {
				color: $primary-color !important;
				border-bottom: 0px !important;
			}
		}
		.load-more {
			color: $primary-color;
			text-transform: uppercase;
			@media (max-width: 768px) {
				font-size: 14px;
			}
		}
	}
	.msg-block {
		overflow: hidden;
		margin-bottom: 0px;
		border-top: 1px solid #fafafa;
		border-bottom: 1px solid #fafafa;
		cursor: pointer;
		&.active {
			background: #f3f3f3;
		}
		&:hover {
			background: #fbfbfb;
		}
		@media (max-width: 320px) {
			text-align: center;
			display: inline-grid;
		}
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.msg-img {
			position: relative;
			@media only screen and (min-width: 600px) and (max-width: 768px) {
				max-width: 100%;
				flex: 0 100%;
			}
			@media (max-width: 320px) {
				margin: auto;
				width: 23%;
			}
			i {
				color: #ffffff;
				position: absolute;
				top: 0px;
				right: 12px;
				height: 20px;
				width: 20px;
				font-size: 12px;
				line-height: 20px;
				background-color: #05b6a5;
				@media (max-width: 769px) {
					top: 5px;
					right: 15px;
				}
			}
		}
		@media (max-width: 768px) {
			p {
				display: none;
			}
		}
		img {
			border-radius: 50%;
		}
		.msg-status {
			padding: 0px;
			text-transform: capitalize;
			line-height: 16px;
			text-align: right;
			@media (max-width: 768px) {
				display: none;
			}
		}
		.msg-date {
			font-size: 12px;
			text-transform: uppercase;
			padding: 0px;
			@media (max-width: 768px) {
				display: none;
			}
			@media (max-width: 480px) {
				padding: 15px 0px;
				float: right !important;
			}
		}
		.msg-list {
			font-size: 12px;
			margin: 0px;
			word-wrap: break-word;
			@media (max-width: 768px) {
				display: none;
			}
		}
	}
	.main-det {
		.padding-com {
			padding: 10px;
			p {
				margin-bottom: 0px;
			}
		}
		.msg-req {
			border: 1px solid #05b6a5;
			overflow: hidden;
			margin-bottom: 15px;

			&.padding-com {
				padding: 10px;
			}

			.msg-note {
				font-size: 14px;
				line-height: 20px;
				word-wrap: break-word;
			}
		}

		.msg-note {
			overflow: hidden;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			@media (max-width: 480px) {
				margin-bottom: 10px;
			}
		}
		img {
			border-radius: 50%;
		}
		.main-name {
			font-size: 22px;
			margin: 15px 0px 5px;
			word-wrap: break-word;
		}
		.main-location {
			font-size: 12px;
			text-transform: uppercase;
		}
		button {
			margin-top: 10px;
		}
		.form-group {
			text-align: center;
		}
		label {
			font-size: 11px;
			color: $primary-color;
			text-transform: uppercase;
		}
		.profile-btn {
			text-align: center;
		}
		.btn {
			margin-right: 5px;
			@media (max-width: 1024px) {
				float: none;
				margin-bottom: 5px;
			}
		}
		.btn-x-small {
			width: 88px;
		}
	}
	.connect-wrap {
		.text-green {
			margin-bottom: 15px;
		}
		.connect-receive-block {
			max-width: 75%;
		}
		.connect-send-block {
			max-width: 75%;
			float: right;
		}
		.old-msg {
			clear: both;
		}
		.connect-block {
			margin-bottom: 10px;
			padding-right: 10px;
			clear: both;
			p {
				margin-bottom: 0;
				word-break: initial;
			}
		}
		.ft-name {
			font-family: 'Montserrat', sans-serif;
			font-weight: bold;
			overflow: hidden;
			line-height: 18px;
			span {
				font-size: 12px;
				color: #c1c1c1;
				font-family: 'Montserrat', sans-serif;
			}
		}
		.btn {
			width: 100%;
			border-radius: 0px;
		}
		.control-label {
			text-transform: uppercase;
			font-size: 20px !important;
			font-family: 'Montserrat', sans-serif !important;
			margin-bottom: 20px !important;
		}
		.form-title {
			font-size: 14px !important;
			font-family: 'Montserrat, sans-serif';
			margin-bottom: 15px;
			color: #38344d;
		}
		form {
			.has-error {
				display: none;
			}
			textarea {
				height: 75px !important;
				padding: 15px;
				line-height: 18px;
				font-size: 14px;
			}
			textarea.form-control {
				border: 0px;
				border-radius: 0px;
				height: auto;
			}
			.btn {
				margin-top: -10px;
			}
		}
	}
}

/*DESKTOP AND MOBILE VIEW*/
@media (min-width: 576px) {
	.mobile-view {
		display: none;
	}
	.desktop-view {
		display: inline;
	}
	.desktop-view {
		.conn-filter-wrap {
			@media (min-width: 768px) {
				display: none;
			}
		}
		.msg-wrap {
			.top-title {
				margin-bottom: 0px;
			}
			.form-signup .form-group {
				margin-bottom: 0px;
			}
			ul.nav.nav-tabs {
				display: inline-flex;
				padding-left: 10px;
				@media (max-width: 767px) {
					display: none;
				}
				a.nav-link {
					font-size: 14px !important;
					margin-right: 20px;
				}
			}
		}
	}
}
@media (max-width: 575px) {
	.desktop-view {
		display: none;
	}

	.mobile-view {
		font-size: 12px;
		line-height: 18px;
		display: inline;
		.msg-wrap {
			overflow-x: scroll;
			width: auto;
			max-height: 100px;
			white-space: nowrap;
			.text-center {
				display: none;
			}
		}
		.msg-block {
			width: 20%;
			display: inline-flex;
			border: 0px;
			img {
				opacity: 0.45;
			}
		}
		.msg-block.active {
			background: none;
			img {
				opacity: 1;
			}
		}
		.msg-md {
			padding: 0px 15px;
			margin-bottom: 10px;
		}

		.main-det {
			display: flex;
			flex-direction: column-reverse;
			.padding-com {
				padding: 8px !important;
			}
			.main-name {
				font-size: 14px;
				margin: 0px;
			}
			.main-location {
				line-height: 14px;
			}
		}
		.col-7.msg-md {
			padding: 0px 15px 0px 0px;
		}
		.badges-wrap span.digital_id {
			width: 20px;
		}
		.badges-wrap span.police_check {
			width: 20px;
		}
		.msg-req {
			margin-bottom: 10px !important;
		}
		.msg-note {
			font-size: 11px !important;
			line-height: 16px !important;
		}
		.paddingleft {
			padding: 0px 15px 0px 0px;
		}
		.paddingright {
			padding: 0px 0px 0px 15px;
		}
		.profile-des {
			text-align: left;
		}
		.block-border {
			margin-bottom: 0px;
		}
		.main-det p {
			display: none;
		}
		.connect-profile {
			margin-bottom: 10px;
			overflow: hidden;
			img {
				border-radius: 50%;
			}
			.btn-x-small {
				display: none;
			}
			.fa {
				color: #444;
				border: 1px solid #444;
				height: 18px;
				width: 18px;
				font-size: 14px;
			}
		}
		.img-pr img {
			width: 60%;
		}
		ul.nav-tabs {
			display: inline-flex;
			margin-bottom: 10px;
			a {
				font-size: 16px;
				padding-bottom: 1px !important;
			}
			li .nav-link.active {
				margin-bottom: 0px !important;
			}
			li .nav-link:not(.active) {
				display: inline-block !important;
			}
		}
	}
}

/*MODAL*/
body.modal-open {
	height: 100vh;
	overflow: hidden;
}

/* Bootstrap Lightbox using Modal */

#profile-grid {
	overflow: auto;
	white-space: normal;
}
#profile-grid .profile {
	padding-bottom: 40px;
}
#profile-grid .panel {
	padding: 0;
}
#profile-grid .panel-body {
	padding: 15px;
}
#profile-grid .profile-name {
	font-weight: bold;
}
#profile-grid .thumbnail {
	margin-bottom: 6px;
}
#profile-grid .panel-thumbnail {
	overflow: hidden;
}
#profile-grid .img-rounded {
	border-radius: 4px 4px 0 0;
}

/*********XCHANGE**********/
.xchange {
	font-size: 14px;
	.padding-com {
		padding: 10px 10px 20px;
	}
	.top-title {
		margin-bottom: 0px;
		font-size: 18px;
	}
	.xch-btn {
		margin-top: 10px;
	}
	p {
		font-size: 12px;
		line-height: 20px;
		padding: 5px 0px 0px;
		margin-bottom: 0px;
		@media (max-width: 320px) {
			padding: 0px;
		}
	}

	.xchange-block .btn {
		display: inline-block;
	}
	.xchange-block {
		border-bottom: 1px solid #f1f1f1;
		padding-top: 0px;
		margin-bottom: 25px;
		&:first-child {
			padding-top: 0px;
		}
		&:last-child {
			border-bottom: 0px;
			margin-bottom: 0px;
		}
	}
}

.x-req {
	@media only screen and (max-width: 768px) and (min-width: 600px) {
		.msg-lt {
			max-width: 100% !important;
		}
	}
	.xch-num {
		background: #ef7f48;
		height: 25px;
		width: 25px;
		text-align: center;
		border-radius: 50%;
		color: #ffffff;
		display: inline-table;
		vertical-align: middle;
		float: right;
	}
	.msg-block {
		overflow: visible;
		width: 20%;
		display: inline-block;
		border: 0px;
	}

	.msg-wrap {
		overflow: hidden;
		width: auto;
		white-space: pre-line;
		margin-bottom: 0px;
		.text-center {
			display: none;
		}
		.padding-com {
			padding: 5px;
		}
	}
	.block-border {
		border: 0px;
	}
	.msg-img {
		margin-bottom: 6px;
	}
	.msg-list {
		text-align: center;
		line-height: 18px;
		p {
			display: none;
		}
	}
	.msg-date {
		display: none;
	}
	.msg-status {
		display: none;
	}
}

/****************** GUEST/HOST ACCOUNT ******************************/

.acc-form {
	.acc-top-form {
		overflow: hidden;
	}
}

.pass-update {
	clear: both;
	overflow: hidden;
}

.deactive-acc {
	margin-top: 20px;
	.btn-secondary {
		margin-right: 12px;
	}
	.de-title {
		margin-bottom: 30px;
	}
	.de-des {
		font-size: 14px;
		margin-bottom: 30px;
	}
}

.pre-invoice {
	ul {
		list-style: none;
		padding: 0px;
	}
	li {
		font-size: 14px;
		margin-bottom: 12px;
		display: inline-flex;
		a.download-inv {
			background: url('/themes/theroomxchange/assets/images/list-arrow.png')
				no-repeat;
			span {
				margin-right: 8px;
			}
		}
		span {
			margin-left: 16px;
			line-height: 22px;
		}
	}
}
.payment-rt {
	.pre-invoice {
		ul {
			margin-bottom: 0px !important;
			p {
				margin-bottom: 0px !important;
			}
		}
	}
	.prev-title {
		color: #999999;
	}
	.view-invoice {
		color: $primary-color;
		text-transform: uppercase;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
	}
}

/*PAYMENT*/
.payment {
	.form-signup select.form-control,
	.form-control {
		height: 42px !important;
	}
	.top-title {
		margin-bottom: 15px !important;
	}

	.payment-lf {
		@media (max-width: 767px) {
			overflow: hidden;
			margin-bottom: 20px;
		}

		.add-new {
			margin-bottom: 10px;
			i {
				color: #ef7f49;
				border: 1px solid #ef7f49;
				display: inline-flex;
				margin-left: 15px;
			}
		}
		.btn-pay {
			min-width: 100px;
			margin-top: 0px;
			@media (max-width: 991px) {
				min-width: 80px;
			}
			@media (max-width: 767px) {
				margin-left: 15px;
			}
		}
		.form-signup .form-group:empty {
			display: none;
		}
		.form-check {
			label,
			input {
				color: #999999;
			}
		}
		.visa {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 0% 11%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.dinersclub {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 0% 99%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.unionpay {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 34% 99%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.jcb {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 66% 99%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.mastercard {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 34% 11%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.americanexpress {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 66% 11%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.discover {
			background: url(/themes/theroomxchange/assets/images/cards.png)
				no-repeat 98% 11%;
			width: 45px;
			height: 30px;
			display: inline-block;
		}
		.input {
			border: 1px solid #ced4da;
			width: auto;
			border-radius: 30px;
			height: 42px;
			padding: 7px 10px;
			span {
				vertical-align: top;
				color: #7e7f80;
				padding-left: 10px;
			}

			a {
				float: right;
				@media (max-width: 320px) {
					margin-top: 6px;
				}
			}
		}
	}
}

/*CARD MODAL*/
.card-element {
	border: 1px solid #ced4da;
	border-radius: 30px;
	padding: 12px;
	height: 42px;
}
/*PAYMENT MODAL*/
.payment-modal {
	.modal-body {
		text-align: left;
	}
	.form-signup {
		margin-top: 10px;
	}
	.form-group {
		text-align: left;
	}
	.btn-green {
		margin-right: 10px !important;
	}
	.btn-org {
		@media (max-width: 480px) {
			margin-bottom: 10px !important;
		}
		@media only screen and (max-width: 768px) {
			margin-bottom: 10px !important;
		}
	}
}

/*INVOICE MODAL*/
.invoice-modal {
	.modal-body {
		padding: 10px 80px;
	}
	.pre-invoice {
		li {
			border-bottom: 1px solid #ece9e9;
			padding-bottom: 10px;
		}
	}
}
// Typehead select from the list
.search-wrap .rbt.open .rbt-menu li:first-child::before {
	content: 'Choose postcode and location';
	padding: 0.25rem 1.5rem;
	display: block;
	width: 100%;
	color: #ef7f49;
	text-align: inherit;
	font-size: 17px;
}
//google maps
.gm-style-iw + div {
	display: none;
}
#map .info-wrap {
	max-width: 180px;
	margin-left: 10px;

	.text {
		font-size: 16px;
		margin-bottom: 5px;
		font-weight: 400;
		font-family: 'Montserrat', sans-serif;
	}

	.link a {
		color: #ef7f49;
		font-family: 'Montserrat', sans-serif;
	}
}

.row.message-abuse-wrapper {
	margin-top: 40px;
	.message-abouse-title {
		font-size: 15px;
		color: darkgray;
		word-wrap: break-word;
	}
}

.mobile-view {
	.row.message-abuse-wrapper {
		margin-top: 10px;
		padding: 10px;
		.message-abouse-title {
			font-size: 12px;
			color: darkgray;
			word-wrap: break-word;
		}
	}
}

.model-connect-connect-btn {
	width: 171px !important;
}

.card-header__left {
	display: flex;
	align-items: center;
	text-transform: none;
}

.card-header__left--icon {
	margin-left: 8px;
	font-size: 10px;
	height: 15px;
	width: 15px;
	color: #95989a;
	border: 1px solid #95989a;
}

.dashboard-info {
	margin-left: 5px;
	margin-right: 5px;
	line-height: 18px;
}

.dash-basic {
	[class^='col-'] {
		padding-right: 0;
	}
}

.block-title.dashboard-info__value {
	display: flex;
	align-items: center;
}

.box {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	border-radius: 20px;
	background-color: #fff;
	.box-body {
		padding: 1.25rem;
	}
	&.box-bottom-margin {
		margin-bottom: 32px;
	}
}

.card-wrapper {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
  0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 2rem;
  &.bg-light{
	  background-color: #FFF !important;
  }
}


.horizontal-org {
  border: 0.5px solid #ef7f49;
}