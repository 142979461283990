@mixin popover-elements {
	.user-location {
		display: block;
		margin-bottom: 1em;

		&::before {
			font-family: 'FontAwesome', monospace;
			font-weight: 900;
			content: '\f041';
			margin-right: 0.4em;
			font-size: 1.5em;
			color: $primary-color;
		}
	}

	.user-bio {
		display: block;
		width: 100%;
		margin-bottom: 1.25em;
	}

	.ready-to-xchange {
		margin-bottom: 1em;

		.ready-header {
			font-weight: 600;
			display: block;
		}

		.ready-content {
			display: block;
		}
	}
}

.user-expandable-icon {
	margin: 0 0.8em;
	vertical-align: top;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	transition: 0.6s ease;

	@media (max-width: 768px) {
		transition: none;
		padding: 12px;
		margin: 0;
		&.selected-user {
			background-color: rgba(229, 229, 229, 0.6);
			border-radius: 0.7em 0.7em;
			border: 2px solid rgba(229, 229, 229, 1);
		}
	}

	&:first-child {
		margin-left: 0;
	}

	&:hover * img {
		opacity: 0.8;
		filter: alpha(opacity=80);
		transform: translateY(-0.2em);
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	}
}

.user-expandable-popover-wrapper {
	.arrow {
		position: absolute;
		width: 10px;
		height: 10px;

		&:after {
			content: ' ';
			position: absolute;
			top: -20px;
			left: 0;
			transform: rotate(45deg);
			width: 10px;
			height: 10px;
			background-color: white;
			box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
		}
	}

	&[data-popper-placement^='top'] * .arrow {
		bottom: -25px;
	}

	&[data-popper-placement^='top'] * .arrow:after {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
	}

	.popover-content {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
		padding: 15px;
		width: 340px;
		z-index: 101;
		line-height: 1.3em;
		transition: 0.1s ease-out;
		text-align: center;
		@include popover-elements;
	}
	@media (max-width: 768px) {
		display: none;
	}
}

.dashboard-user-card {
	text-align: center;
	margin-top: 20px;
	padding: 0;
	max-height: 0;
	overflow-y: hidden;
	-webkit-transition: max-height 0.8s ease-in-out;
	-moz-transition: max-height 0.8s ease-in-out;
	-o-transition: max-height 0.8s ease-in-out;
	transition: max-height 0.8s ease-in-out;
	&.show {
		max-height: 450px;
		.user-card-wraper {
			padding: 20px;
		}
	}
	@include popover-elements;
	@media (min-width: 768px) {
		display: none;
	}
}
