.user-icon-profile-image {
	vertical-align: top;

	img {
		border-radius: 50%;
		object-fit: cover;
	}
}

.user-icon-user-name {
	font-weight: 600;
	font-size: 1.15em;
	line-height: 1.15em;
	padding-top: 0.6em;
	display: block;
}
