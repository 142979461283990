div.xchange-search-filter {
    display: inline-block;
    margin: 0.5em;
    font-weight: 500;
    position: relative;
    select{
        color: #666666;
        line-height: 1.15;
        padding: 0.8em 2em 0.8em 0.8em;
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
        border-radius: 10.5em;
        -webkit-appearance: none;
        appearance: none;
    }
    &:after {
        content: "\f107";
        font-family: FontAwesome;
        color: #888;
        display: inline-block;
        position: absolute;
        right: 11px;
        pointer-events: none;
        line-height: 1.15;
        padding: 0.8em 0;
    }
}
