.xchange__form {
  text-align: left;

  .form-control {
    border-radius: 30px;
    height: 54px;
  }

  .control-label {
    font-weight: 600;
  }
}

.xchange__row {
  margin-bottom: 16px;
}

.xchange__controls {
  text-align: center;
  margin-top: 16px;
}

.xchange__controls__btn {
  &.btn {
    height: 50px;
    min-width: 200px;
    font-size: 14px;
  }
}
