.sms-notification__options {
  label {
    align-items: center;
    margin-right: 32px;

    span {
      margin-left: 16px;
    }
  }
}
