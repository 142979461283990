.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  margin-top: 16px;
  z-index: 99;
  width: 100%;
}

.react-autosuggest__suggestions-list {
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 0;
  overflow: auto;
  max-height: 320px;
  background: white;
  border: 1px solid #ced4da;
  padding-left: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #05b6a5;
  }
}

.react-autosuggest__suggestion {
  list-style: none;
  padding-bottom: 8px;
  padding-left: 24px;
  cursor: pointer;

  &:hover {
    color: #05b6a5;
  }
}

.react-autosuggest__suggestion--highlighted {
  color: #05b6a5;
}
