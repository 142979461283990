.resource-summary {
	a.resource-summary-anchor {
		text-decoration: none;
		display: flex;

		i {
			background-color: $primary-color;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			font-size: 30px;
			text-align: center;
			line-height: 60px;
			color: white;
			display: inline-block;
			float: left;
			margin-right: 0.6em;
			transition: 0.6s ease;
			box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
		}

		&:hover * i {
			background-color: lighten($primary-color, 5%);
			transform: translateY(-0.2em);
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
		}
	}

	.resource-content {
		display: inline;
		vertical-align: top;
		h4 {
			color: $base-text-color;
		}
	}

}
