.signup__steps__navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f1efef;
}

.single__step__navigation {
  opacity: 0.5;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  &.active {
    opacity: 1;

    .single__step__title {
      color: $second-color;
    }
  }
}

.single__step__title {
  margin-bottom: 0;
  font-size: 18px;
}

.signup__controls {
  display: flex;
  justify-content: center;

  .btn-green {
    margin-left: 1rem;
  }
}

.signup__controls--right {
  justify-content: flex-end;
}

.password__requirement {
  padding-bottom: 32px;
}

.password__requirement__term {
  text-transform: none;
  color: #555353;
  opacity: 0.5;

  &.error {
    color: #ea3b3b;
    opacity: 1;
  }

  &.correct {
    color: $second-color;
    opacity: 1;
  }
}

.signup__errors {
  margin-bottom: 32px;
  font-size: 16px;
  background-color: #ea3b3b;
  color: white;
  padding: 16px;
}

.international-user-modal {
  .modal-content {
    overflow: hidden;
    padding: 0;

    .modal-header {
      padding: 0;
      padding-bottom: 15px;
    }

    .modal-footer {
      padding: 0;

      .modal__btn__submit {
        margin-right: 0;
        margin-bottom: 16px;

        @media screen and (min-width: 768px) {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }
  }

  .modal-body {
    overflow: auto;
    padding: 48px;
  }

  .modal-close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 2;

    .close {
      padding: 0px !important;
    }

    i {
      border: 1px solid #95989a;
      color: #95989a;
    }
  }
}

.form-signup {
  .empty-control-label {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  input[type='date'].form-control {
    display: flex;
  }
}

.form-signup {
  .form-control {
    border-radius: 30px;
    height: 54px;
  }

  select.form-control {
    height: 54px !important;
    margin-bottom: 10px;
  }

  label {
    display: inline-flex;
    margin-bottom: 7px;
  }

  .control-label {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  .dob-readonly {
    .react-datepicker-wrapper {
      background-color: #e9ecef;
      input {
        background-color: #e9ecef;
      }
    }
  }

  .fa {
    margin: -8px 9px;
  }

  .btn-signup-role {
    background-color: #ffffff;
    height: 54px !important;
    margin-right: 20px;
    width: 200px;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover,
    &.active {
      background-color: $primary-color;
      border: none;
      color: #ffff;
    }
    &:hover {
      background-color: rgba(239, 127, 73, 0.8);
    }

    &.be-a-guest {
      border: 1px solid $second-color;
      color: $second-color;

      &.active {
        background-color: $second-color;
        border: none;
        color: #ffff;
      }
      &:hover {
        background-color: $second-color;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px !important;

    label[for='last-name'] {
      display: none;
    }
  }
}

label.form-check {
  display: block;
}

.form-check-input {
  border: 1px solid #444;
  border-radius: 50%;
}

.form-check-input:checked {
  background-color: $primary-color;
  border: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
}

.trx-signup-form {
  h1 {
    margin-bottom: .75rem;
    color: #555353;
    font-weight: 500;
    font-size: 34px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .phone__form-group {
    margin-bottom: 16px;
  }

  .phone__requirement__term {
    margin-bottom: 16px;
    line-height: 1.25rem;
    text-transform: none;
    color: rgba(85,83,83, 0.65);
  }
}

.row__gender {
  display: flex;
  margin-bottom: 36px;
  align-items: center;

  .form-group {
    margin-bottom: 0;
  }

  .field_gender__other {
    display: flex;
    align-items: center;

    span {
      margin-right: 36px;
    }

    .form-group {
      flex: 1 1 auto;
    }
  }
}

.text-field__label {
  display: flex;
  align-items: center;
}

.register-intro-text {
  p {
    font-size: 16px;
    color: rgba(67, 66, 65, 0.65);
    font-family: 'Montserrat', sans-serif !important;
    line-height: 1.75rem;
  }
}

.international-user-modal {
  .modal-content {
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    border-radius: 2rem;
  }
}

.btn-small {
  min-width: unset;
}

.international-wrap {
  margin-top: 1rem;

  p {
    color: rgba(67, 66, 65, 0.65);
  }

  .international-text {
    font-weight: 600;
  }

  .btn-international {
    line-height: 18px;
    height: unset;
    margin-left: 5px;

    &.international {
      &.active {
        background-color: #565455;
        border: none;
        color: #ffff;
      }

      &:hover {
        background-color: #565455;
        color: #ffff;
      }
    }
  }
}

.small-text {
 color: rgba(85,83,83, 0.65);
}