.login-card {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 30px;
  background-color: #FFF;
  margin: 0 auto;
  width: 500px;
  @media (max-width: 550px) {
    width: 100%;
  }
}
