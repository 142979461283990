.user__exchanging_status {
  padding: 4px;
  color: white;
  background-color: #05b6a5;
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-transform: uppercase;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
