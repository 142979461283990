.user-list-icons-wrapper {
	margin-bottom: 1.25em;
	min-height: 170px;

	.user-list {
		padding-top: 0.5em;
		width: 100%;
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 25px;
	}
}
